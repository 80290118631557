define("console/templates/components/package-slip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "79pNGe4z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-4 page-break-after\"],[8],[0,\"\\n  \"],[1,[28,\"bar-code\",null,[[\"value\",\"format\",\"class\",\"options\"],[[24,[\"product\",\"sku\"]],\"code39\",\"block w-full h-12\",[28,\"hash\",null,[[\"displayValue\",\"width\",\"margin\"],[false,3,0]]]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex justify-between mt-2 text-xs font-medium whitespace-no-wrap\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[24,[\"product\",\"fullTitle\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[24,[\"product\",\"category\",\"title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/package-slip.hbs"
    }
  });

  _exports.default = _default;
});