define("console/templates/components/barcode-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YKNCPWJY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed flex justify-center pin-l pin-r pin-b z-10 pointer-events-none\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8 pointer-events-auto\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"block text-white p-4 bg-grey rounded-full shadow-lg\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"scannerIsVisible\"]]],null],true]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"barcode-scanner\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"scannerIsVisible\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"wormhole\"]],{\"statements\":[[0,\"    \"],[1,[28,\"barcode-scanner\",null,[[\"onScan\",\"close\"],[[28,\"action\",[[23,0,[]],[24,[\"onScan\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"scannerIsVisible\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/barcode-btn.hbs"
    }
  });

  _exports.default = _default;
});