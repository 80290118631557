define("console/models/customer", ["exports", "console/models/user", "ember-data"], function (_exports, _user, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  var INTERCOM_URL = 'https://app.intercom.com/apps/iig24elh/users/show';

  var _default = _user.default.extend({
    ordersCount: attr('number', {
      defaultValue: 0
    }),
    invoicedOrdersCount: attr('number', {
      defaultValue: 0
    }),
    lastHtoOrderAt: attr('date'),
    lastOrderAt: attr('date'),
    shippingAddresses: hasMany('address'),
    billingAddresses: hasMany('address'),
    prescriptions: hasMany('prescription'),
    orders: hasMany('order'),
    appointments: hasMany('appointment'),
    paidOrders: Ember.computed.filterBy('orders', 'isPaid'),
    htoOrders: Ember.computed.filterBy('paidOrders', 'isHto'),
    regularOrders: Ember.computed.filterBy('paidOrders', 'isHto', false),
    hasRegularOrders: Ember.computed.notEmpty('regularOrders'),
    hasHtoOrders: Ember.computed.notEmpty('htoOrders'),
    hasShippingAddress: Ember.computed.bool('shippingAddress.id'),
    hasBillingAddress: Ember.computed.bool('billingAddress.id'),
    hasPrescription: Ember.computed.bool('prescription.id'),
    addressesSorting: Ember.A(['createdAt:desc']),
    prescriptionsSorting: Ember.A(['createdAt:desc']),
    sortedShippingAddresses: Ember.computed.sort('shippingAddresses', 'addressesSorting'),
    sortedBillingAddresses: Ember.computed.sort('shippingAddresses', 'addressesSorting'),
    sortedPrescriptions: Ember.computed.sort('prescriptions', 'prescriptionsSorting'),
    shippingAddress: Ember.computed.readOnly('sortedShippingAddresses.firstObject'),
    billingAddress: Ember.computed.readOnly('sortedBillingAddresses.firstObject'),
    prescription: Ember.computed.readOnly('sortedPrescriptions.firstObject'),
    isFlagged: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('flagged');
    }),
    intercomUrl: Ember.computed('email', function () {
      return "".concat(INTERCOM_URL, "?email=").concat(this.email);
    })
  });

  _exports.default = _default;
});