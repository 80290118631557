define("console/templates/components/order-pick-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9DDeESdB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"sm\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"order-pick-up/header\",null,[[\"order\",\"close\",\"generateReturnTrackingNumber\"],[[24,[\"order\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"generateReturnTrackingNumber\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"order-pick-up/fields\",null,[[\"order\",\"pickupAt\"],[[24,[\"order\"]],[24,[\"pickupAt\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pt-4 px-8 pb-8 bg-grey-lightest rounded-b\"],[8],[0,\"\\n    \"],[1,[28,\"address-section\",null,[[\"title\",\"address\",\"parent\"],[\"Shipping Address\",[24,[\"order\",\"shippingAddress\"]],[24,[\"order\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-pick-up.hbs"
    }
  });

  _exports.default = _default;
});