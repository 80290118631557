define("console/templates/components/pickup-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q33dcRsP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-4 page-break-after\"],[8],[0,\"\\n  \"],[1,[28,\"bar-code\",null,[[\"value\",\"format\",\"class\",\"options\"],[[24,[\"order\",\"number\"]],\"code39\",\"block w-full h-12\",[28,\"hash\",null,[[\"displayValue\",\"width\",\"margin\"],[false,4,0]]]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex justify-between mt-2 font-medium whitespace-no-wrap\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[24,[\"order\",\"customer\",\"firstName\"]],false],[0,\"\\n      \"],[1,[28,\"truncate\",[[24,[\"order\",\"customer\",\"lastName\"]],2,false],null],false],[0,\".\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"if\",[[24,[\"order\",\"hasShippingAddress\"]],\"D\",[28,\"truncate\",[[24,[\"order\",\"location\",\"title\"]],2,false],null]],null],false],[0,\"\\n      — #\"],[1,[24,[\"order\",\"number\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/pickup-label.hbs"
    }
  });

  _exports.default = _default;
});