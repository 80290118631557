define("console/routes/customers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading"], function (_exports, _authenticatedRouteMixin, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    queryParams: {
      search: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var search = _ref.search,
          page = _ref.page,
          sort = _ref.sort;
      var params = {
        page: {
          number: page
        },
        sort: sort
      };

      if (!Ember.isEmpty(search)) {
        params.filter = {
          first_name_or_last_name_or_phone_cont: search
        };
      }

      return this.store.query('customer', params);
    }
  });

  _exports.default = _default;
});