define("console/controllers/invoices", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'MMM-YYYY';

  var _default = Ember.Controller.extend({
    queryParams: ['month', 'page'],
    page: 1,
    monthOptions: Ember.A([(0, _moment.default)().format(DATE_FORMAT), (0, _moment.default)().subtract(1, 'month').format(DATE_FORMAT)]),
    month: Ember.computed.reads('monthOptions.lastObject'),
    orders: Ember.computed.readOnly('model'),
    actions: {
      print: function print() {
        window.print();
      }
    }
  });

  _exports.default = _default;
});