define("console/components/ui-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pages: Ember.computed.or('pagination.last', 'pagination.current'),
    isVisible: Ember.computed.gt('pages', 1)
  });

  _exports.default = _default;
});