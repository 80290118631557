define("console/components/ui-cycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      cycle: function cycle(next) {
        this.update(next == this.value ? this.options[0] : next);
      }
    }
  });

  _exports.default = _default;
});