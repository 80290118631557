define("console/controllers/lab/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    item: Ember.computed.readOnly('model')
  });

  _exports.default = _default;
});