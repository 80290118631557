define("console/templates/components/manager-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+chW7lhk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"sm\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"manager-editor/header\",null,[[\"manager\",\"close\",\"save\"],[[24,[\"manager\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"manager-editor/fields\",null,[[\"manager\"],[[24,[\"manager\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/manager-editor.hbs"
    }
  });

  _exports.default = _default;
});