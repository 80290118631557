define("console/components/product-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var categories = this.store.peekAll('category');
      var category = categories.findBy('slug', this.category);
      this.set('products', this.store.query('product', {
        filter: {
          category_id_eq: category.id
        }
      }));
    },
    store: Ember.inject.service(),
    productsSorting: Ember.A(['title:asc', 'variant:asc']),
    sortedProducts: Ember.computed.sort('products', 'productsSorting')
  });

  _exports.default = _default;
});