define("console/controllers/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var readOnly = Ember.computed.readOnly,
      sort = Ember.computed.sort;

  var _default = Ember.Controller.extend({
    queryParams: ['category', 'search', 'view'],
    category: null,
    search: '',
    view: 'grid',
    products: readOnly('model.products'),
    productsSorting: Ember.A(['isPublic:desc', 'title:asc', 'variant:asc']),
    sortedProducts: sort('products', 'productsSorting'),
    categories: readOnly('model.categories'),
    actions: {
      searchByCode: function searchByCode(code) {
        this.set('scannerIsVisible', false);
        this.set('search', code);
      }
    }
  });

  _exports.default = _default;
});