define("console/templates/lab/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "su3o+LAL",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"concat\",[\"#\",[24,[\"item\",\"order\",\"number\"]]],null]],null],false],[0,\"\\n\"],[1,[28,\"lab-item\",null,[[\"item\",\"onComplete\",\"close\"],[[24,[\"item\"]],[28,\"transition-to\",[\"lab\"],null],[28,\"transition-to\",[\"lab\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/lab/item.hbs"
    }
  });

  _exports.default = _default;
});