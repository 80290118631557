define("console/controllers/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locations: Ember.computed.readOnly('model'),
    locationsSorting: Ember.A(['title:asc']),
    sortedLocations: Ember.computed.sort('locations', 'locationsSorting')
  });

  _exports.default = _default;
});