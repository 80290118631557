define("console/translations/ro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "invoice": {
      "category": {
        "clipOns": "Clip-on",
        "glasses": "Ochelari de vedere",
        "sunglasses": "Ochelari de soare"
      },
      "label": {
        "discount": "Reducere",
        "item": "Articol",
        "orderNumber": "Număr comandă",
        "payment": "Referință plată",
        "price": "Preț",
        "quantity": "({count} Buc.)",
        "total": "Total",
        "totalVat": "Total TVA",
        "vat": "TVA",
        "vatRate": "Cotă TVA"
      },
      "surcharge": {
        "blueLightFilter": "Filtru protecție calculator",
        "degressiveLenses": "Lentile degresive",
        "highIndex": "Lentile premium",
        "lightResponsiveFilter": "Filtru heliomat",
        "noPrescription": "Fără dioptrii",
        "polarizingFilter": "Filtru polarizare",
        "progressiveLenses": "Lentile progresive",
        "singleVisionLenses": "Lentile monofocale"
      },
      "title": "Factura"
    },
    "pos": {
      "blueLightFilter": "Blue",
      "highIndex": "Premium",
      "lightResponsiveFilter": "Helio",
      "polarizingFilter": "Polar"
    },
    "prescription": {
      "label": {
        "add": "ADD",
        "axis": "AX",
        "cylinder": "CYL",
        "diagnosis": "Diagnostic",
        "medic": "Medic oftalmolog",
        "optometrist": "Optometrist",
        "prism": "PR",
        "pupilDistance": "DP",
        "segmentHeight": "SH",
        "sphere": "SPH"
      },
      "title": "Prescripție optometrică"
    },
    "transport": {
      "title": "Aviz de însoțire a mărfii"
    }
  };
  _exports.default = _default;
});