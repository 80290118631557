define("console/router", ["exports", "console/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('orders', function () {
      this.route('order', {
        path: '/:order_id'
      });
    });
    this.route('customers', function () {
      this.route('customer', {
        path: '/:customer_id'
      });
    });
    this.route('products', function () {
      this.route('product', {
        path: '/:product_id'
      });
      this.route('new');
    });
    this.route('discounts', function () {
      this.route('discount', {
        path: '/:discount_id'
      });
      this.route('new');
    });
    this.route('shops', function () {
      this.route('shop', {
        path: '/:shop_id'
      });
    });
    this.route('jobs', function () {
      this.route('job', {
        path: '/:job_id'
      });
    });
    this.route('invoices');
    this.route('pos', function () {
      this.route('order', {
        path: '/:order_id'
      }, function () {
        this.route('charge');
        this.route('complete');
      });
    });
    this.route('appointments', function () {
      this.route('appointment', {
        path: '/:appointment_id'
      });
    });
    this.route('locations', function () {
      this.route('location', {
        path: '/:location_id'
      });
    });
    this.route('managers', function () {
      this.route('manager', {
        path: '/:manager_id'
      });
      this.route('new');
    });
    this.route('categories', function () {
      this.route('category', {
        path: '/:category_id'
      });
    });
    this.route('lab', function () {
      this.route('item', {
        path: '/:item_id'
      });
    });
    this.route('sales');
  });
  var _default = Router;
  _exports.default = _default;
});