define("console/templates/components/ui-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8rbP8Kxl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"block text-lg no-underline bg-grey-lighter p-2 rounded-full text-center mr-4\\n    \",[28,\"if\",[[24,[\"pagination\",\"prev\"]],\"text-blue\",\"text-grey\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],[28,\"or\",[[24,[\"pagination\",\"prev\"]],[24,[\"pagination\",\"current\"]]],null]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"arrow-left\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"block text-grey\"],[8],[0,\"\\n    \"],[1,[24,[\"pagination\",\"current\"]],false],[0,\" / \"],[1,[22,\"pages\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"block text-lg no-underline bg-grey-lighter p-2 rounded-full text-center ml-4\\n    \",[28,\"if\",[[24,[\"pagination\",\"next\"]],\"text-blue\",\"text-grey\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],[28,\"or\",[[24,[\"pagination\",\"next\"]],[24,[\"pagination\",\"current\"]]],null]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"arrow-right\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-pagination.hbs"
    }
  });

  _exports.default = _default;
});