define("console/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.session.isTechnician) {
        this.transitionTo('lab');
      } else if (!this.session.isManager) {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});