define("console/controllers/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    categories: Ember.computed.readOnly('model'),
    categoriesSorting: Ember.A(['position:asc']),
    sortedCategories: Ember.computed.sort('categories', 'categoriesSorting')
  });

  _exports.default = _default;
});