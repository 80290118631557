define("console/templates/components/ui-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mSzCO2j/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"inline-block py-1 px-3 rounded-full border\\n  no-underline text-sm \",[28,\"if\",[[24,[\"checked\"]],\"bg-blue border-blue text-white\",\"bg-grey-lightest text-black\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"update\"]],[28,\"not\",[[24,[\"checked\"]]],null]]],[8],[0,\"\\n  \"],[1,[28,\"humanize\",[[24,[\"label\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-toggle.hbs"
    }
  });

  _exports.default = _default;
});