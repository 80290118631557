define("console/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PRODUCT_TYPES = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var readOnly = Ember.computed.readOnly,
      sort = Ember.computed.sort,
      or = Ember.computed.or,
      lte = Ember.computed.lte;
  var PRODUCT_TYPES = ['Product', 'Gift'];
  _exports.PRODUCT_TYPES = PRODUCT_TYPES;

  var _default = Model.extend({
    title: attr('string'),
    variant: attr('string'),
    sku: attr('string'),
    stock: attr('number', {
      defaultValue: 0
    }),
    sold: attr('number', {
      defaultValue: 0
    }),
    available: attr('number', {
      defaultValue: 0
    }),
    inHto: attr('number', {
      defaultValue: 0
    }),
    price: attr('number', {
      defaultValue: 0
    }),
    exchangedPrice: attr('number', {
      defaultValue: 0
    }),
    acquisitionCost: attr('number', {
      defaultValue: 0
    }),
    position: attr('number', {
      defaultValue: 0
    }),
    descriptionRo: attr('string'),
    descriptionEn: attr('string'),
    material: attr('string'),
    shape: attr('string'),
    width: attr('string', {
      defaultValue: 'Medium'
    }),
    lensColor: attr('string'),
    measurements: attr('string'),
    type: attr('string', {
      defaultValue: PRODUCT_TYPES[0]
    }),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    category: belongsTo('category'),
    images: hasMany('image'),
    linkedProducts: hasMany('product', {
      inverse: null
    }),
    categoryId: readOnly('category.id'),
    categorySlug: readOnly('category.slug'),
    imageSorting: Ember.A(['position:asc']),
    sortedImages: sort('images', 'imageSorting'),
    image: readOnly('sortedImages.firstObject'),
    isMetal: Ember.computed.equal('material', 'Metal'),
    isGlasses: readOnly('category.isGlasses'),
    isSunglasses: readOnly('category.isSunglasses'),
    isClipOn: readOnly('category.isClipOns'),
    hasLenses: or('isGlasses', 'isSunglasses'),
    hasFilters: or('isGlasses', 'isSunglasses', 'isClipOn'),
    isLowInStock: lte('available', 3),
    fullTitle: Ember.computed('title', 'variant', function () {
      return [this.title, this.isSize && this.width, this.variant].filter(function (t) {
        return t;
      }).join(' ');
    }),
    isPublic: Ember.computed('tags.[]', function () {
      return !(this.tags || []).includes('private');
    }),
    isSize: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('size');
    }),
    clone: function clone() {
      var attrs = this.toJSON();
      attrs.sku = null;
      attrs.category = this.category;
      attrs.images = [];
      attrs.linkedProducts = [];
      return this.store.createRecord('product', attrs);
    }
  });

  _exports.default = _default;
});