define("console/templates/components/order-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uhFFzUJ9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full px-4 mb-8\"],[8],[0,\"\\n    \"],[1,[28,\"ui-textarea\",null,[[\"id\",\"label\",\"rows\",\"value\",\"errors\",\"oninput\",\"onblur\"],[\"order-notes\",\"Notes\",2,[24,[\"order\",\"notes\"]],[24,[\"order\",\"errors\",\"notes\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"order\",\"notes\"]]],null]],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full px-4 mb-8\"],[8],[0,\"\\n    \"],[1,[28,\"ui-autocomplete\",null,[[\"id\",\"label\",\"options\",\"selection\",\"errors\",\"update\"],[\"order-tags\",\"Tags\",[24,[\"tagOptions\"]],[24,[\"order\",\"tags\"]],[24,[\"order\",\"errors\",\"tags\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-editor/fields.hbs"
    }
  });

  _exports.default = _default;
});