define("console/templates/components/job-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuHhdvXt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"job\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"pre\",true],[10,\"class\",\"flex bg-grey-lightest rounded p-6 font-mono overflow-x-auto mb-4 text-xs text-red\"],[8],[0,\"    \"],[1,[24,[\"job\",\"error\"]],false],[0,\"  \"],[9],[0,\"\"]],\"parameters\":[]},null],[7,\"pre\",true],[10,\"class\",\"flex bg-grey-lightest rounded p-6 font-mono overflow-x-auto mb-4 text-xs\"],[8],[0,\"  \"],[1,[24,[\"job\",\"deserializedParams\"]],false],[0,\"\"],[9],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/job-editor/fields.hbs"
    }
  });

  _exports.default = _default;
});