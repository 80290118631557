define("console/routes/jobs", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading"], function (_exports, _authenticatedRouteMixin, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    queryParams: {
      sort: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var sort = _ref.sort,
          page = _ref.page;
      return this.store.query('job', {
        page: {
          number: page,
          size: 10
        },
        sort: sort
      });
    }
  });

  _exports.default = _default;
});