define("console/cldrs/ro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "ro",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          v0 = !s[1],
          t0 = Number(s[0]) == n,
          n100 = t0 && s[0].slice(-2);
      if (ord) return n == 1 ? "one" : "other";
      return n == 1 && v0 ? "one" : !v0 || n == 0 || n != 1 && n100 >= 1 && n100 <= 19 ? "few" : "other";
    },
    "fields": {
      "year": {
        "displayName": "An",
        "relative": {
          "0": "anul acesta",
          "1": "anul viitor",
          "-1": "anul trecut"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} an",
            "few": "peste {0} ani",
            "other": "peste {0} de ani"
          },
          "past": {
            "one": "acum {0} an",
            "few": "acum {0} ani",
            "other": "acum {0} de ani"
          }
        }
      },
      "month": {
        "displayName": "Lună",
        "relative": {
          "0": "luna aceasta",
          "1": "luna viitoare",
          "-1": "luna trecută"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} lună",
            "few": "peste {0} luni",
            "other": "peste {0} de luni"
          },
          "past": {
            "one": "acum {0} lună",
            "few": "acum {0} luni",
            "other": "acum {0} de luni"
          }
        }
      },
      "day": {
        "displayName": "Zi",
        "relative": {
          "0": "azi",
          "1": "mâine",
          "2": "poimâine",
          "-2": "alaltăieri",
          "-1": "ieri"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} zi",
            "few": "peste {0} zile",
            "other": "peste {0} de zile"
          },
          "past": {
            "one": "acum {0} zi",
            "few": "acum {0} zile",
            "other": "acum {0} de zile"
          }
        }
      },
      "hour": {
        "displayName": "Oră",
        "relativeTime": {
          "future": {
            "one": "peste {0} oră",
            "few": "peste {0} ore",
            "other": "peste {0} de ore"
          },
          "past": {
            "one": "acum {0} oră",
            "few": "acum {0} ore",
            "other": "acum {0} de ore"
          }
        }
      },
      "minute": {
        "displayName": "Minut",
        "relativeTime": {
          "future": {
            "one": "peste {0} minut",
            "few": "peste {0} minute",
            "other": "peste {0} de minute"
          },
          "past": {
            "one": "acum {0} minut",
            "few": "acum {0} minute",
            "other": "acum {0} de minute"
          }
        }
      },
      "second": {
        "displayName": "Secundă",
        "relative": {
          "0": "acum"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} secundă",
            "few": "peste {0} secunde",
            "other": "peste {0} de secunde"
          },
          "past": {
            "one": "acum {0} secundă",
            "few": "acum {0} secunde",
            "other": "acum {0} de secunde"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});