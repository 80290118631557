define("console/templates/orders/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FEaOm8bS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"concat\",[\"#\",[24,[\"order\",\"number\"]]],null]],null],false],[0,\"\\n\"],[1,[28,\"order-editor\",null,[[\"order\",\"onSave\",\"onArchive\",\"onReturn\",\"close\"],[[24,[\"order\"]],[28,\"transition-to\",[\"orders\"],null],[28,\"transition-to\",[\"orders\"],null],[28,\"transition-to\",[\"orders\"],null],[28,\"transition-to\",[\"orders\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/orders/order.hbs"
    }
  });

  _exports.default = _default;
});