define("console/models/job", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    queueName: attr('string'),
    priority: attr('number', {
      defaultValue: 0
    }),
    serializedParams: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    error: attr('string'),
    concurrencyKey: attr('string'),
    cronKey: attr('string'),
    retriedGoodJobId: attr('string'),
    scheduledAt: attr('date'),
    performedAt: attr('date'),
    finishedAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    jobClass: Ember.computed.readOnly('serializedParams.job_class'),
    deserializedParams: Ember.computed('serializedParams', function () {
      return JSON.stringify(this.serializedParams, null, 2);
    })
  });

  _exports.default = _default;
});