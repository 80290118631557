define("console/routes/pos/order/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(_ref) {
      var isPaid = _ref.isPaid;

      this._super.apply(this, arguments);

      if (isPaid) {
        this.transitionTo('pos');
      }
    }
  });

  _exports.default = _default;
});