define("console/templates/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zMiYqukp",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"fixed z-50 pin-b m-8 p-4 bg-black text-white rounded shadow\"],[8],[0,\"\\n    \"],[1,[23,1,[\"message\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/flash-messages.hbs"
    }
  });

  _exports.default = _default;
});