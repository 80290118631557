define("console/templates/appointments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X5hGiL4g",
    "block": "{\"symbols\":[\"interval\"],\"statements\":[[1,[28,\"page-title\",[\"Appointments\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Appointments\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-between mb-8 sm:mb-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tabs\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"manager\",\"isAdmin\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"all\",[23,0,[]]],null]],null]],[11,\"class\",[29,[\"tabs__tab \",[28,\"if\",[[24,[\"all\"]],\"tabs__tab--active\"],null]]]],[8],[0,\"\\n          All\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"intervals\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"activeClass\",\"query\",\"route\"],[\"tabs__tab\",\"tabs__tab--active\",[28,\"hash\",null,[[\"interval\",\"page\"],[[23,1,[]],1]]],\"appointments\"]],{\"statements\":[[0,\"          \"],[7,\"span\",true],[8],[0,\"\\n            \"],[1,[28,\"humanize\",[[23,1,[]]],null],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"unless\",[[24,[\"isLoaded\"]],\"opacity-25\"],null]],[8],[0,\"\\n    \"],[1,[28,\"appointment-list\",null,[[\"appointments\"],[[24,[\"appointments\"]]]]],false],[0,\"\\n    \"],[1,[28,\"ui-pagination\",null,[[\"pagination\",\"change\"],[[24,[\"appointments\",\"meta\",\"pagination\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"page\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/appointments.hbs"
    }
  });

  _exports.default = _default;
});