define("console/routes/invoices", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading", "moment"], function (_exports, _authenticatedRouteMixin, _loading, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    queryParams: {
      month: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var month = _ref.month,
          page = _ref.page;
      var date = (0, _moment.default)(month, 'MMM-YYYY');
      var params = {
        filter: {
          invoice_number_null: false,
          paid_at_gteq: date.startOf('month').format(DATE_FORMAT),
          paid_at_lteq: date.endOf('month').add(1, 'day').format(DATE_FORMAT)
        },
        page: {
          number: page
        },
        sort: '-paid_at'
      };
      return this.store.query('order', params);
    }
  });

  _exports.default = _default;
});