define("console/templates/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "epo5H141",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Sales\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Sales\"]]],false],[0,\"\\n  \"],[7,\"iframe\",true],[10,\"class\",\"w-full\"],[10,\"height\",\"500\"],[10,\"allowtransparency\",\"\"],[11,\"src\",[29,[\"http://metabase.luneteyewear.com/public/question/eb89f071-eb92-443a-ae2e-ed6c60073416?location=\",[24,[\"session\",\"manager\",\"location\",\"id\"]],\"#bordered=false&titled=false&hide_parameters=location\"]]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/sales.hbs"
    }
  });

  _exports.default = _default;
});