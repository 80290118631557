define("console/templates/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wproR8a8",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[1,[28,\"page-title\",[\"Orders\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Orders\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex mb-8 sm:mb-4\"],[8],[0,\"\\n    \"],[1,[28,\"list-search-input\",null,[[\"value\",\"identifier\",\"class\",\"update\"],[[24,[\"search\"]],\"orders-search\",\"sm:flex-auto\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"search\"]]],null]],null]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tabs sm:hidden ml-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"statuses\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"activeClass\",\"query\",\"route\"],[\"tabs__tab\",\"tabs__tab--active\",[28,\"hash\",null,[[\"status\",\"page\",\"search\"],[[23,1,[]],1,\"\"]]],\"orders\"]],{\"statements\":[[0,\"          \"],[7,\"span\",true],[8],[0,\"\\n            \"],[1,[28,\"humanize\",[[23,1,[]]],null],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"unless\",[[24,[\"isLoaded\"]],\"opacity-25\"],null]],[8],[0,\"\\n    \"],[1,[28,\"order-list\",null,[[\"orders\",\"lastOrder\",\"status\",\"class\"],[[24,[\"orders\"]],[24,[\"lastOrder\"]],[24,[\"status\"]],\"mb-8\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-pagination\",null,[[\"pagination\",\"change\"],[[24,[\"orders\",\"meta\",\"pagination\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"page\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"barcode-btn\",null,[[\"scannerIsVisible\",\"onScan\"],[[24,[\"scannerIsVisible\"]],[28,\"action\",[[23,0,[]],\"searchByCode\"],null]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/orders.hbs"
    }
  });

  _exports.default = _default;
});