define("console/templates/components/connection-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l/PMt+XB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center fixed pin z-50 bg-white\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"m-auto text-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-block text-red bg-grey-lightest rounded-full p-4 mb-2 shadow\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"wifi-strength-off-outline\"],[[\"width\",\"height\",\"class\"],[\"24\",\"24\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-red\"],[8],[0,\"\\n      Looks like there's something wrong\"],[7,\"br\",true],[8],[9],[0,\"\\n      with your internet connection.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/connection-status.hbs"
    }
  });

  _exports.default = _default;
});