define("console/models/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    smUrl: attr('string'),
    mdUrl: attr('string'),
    lgUrl: attr('string'),
    originalUrl: attr('string'),
    position: attr('number', {
      defaultValue: 0
    }),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isMain: Ember.computed('tags.[]', function () {
      return this.tags.includes('main');
    })
  });

  _exports.default = _default;
});