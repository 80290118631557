define("console/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XcWiixD+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed pin flex items-center justify-center\"],[8],[0,\"\\n  \"],[7,\"form\",false],[12,\"class\",\"block\"],[3,\"action\",[[23,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-64 m-auto relative\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"requirePassword\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"ui-input\",null,[[\"id\",\"placeholder\",\"value\",\"autofocus\",\"mask\",\"oninput\"],[\"login-otp\",\"Code\",[24,[\"otp\"]],true,\"0000\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"otp\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"ui-input\",null,[[\"type\",\"id\",\"placeholder\",\"value\",\"autofocus\",\"oninput\"],[\"email\",\"login-email\",\"Email Address\",[24,[\"email\"]],true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"button\",true],[10,\"class\",\"absolute pin-y pin-r flex items-center text-blue focus:outline-none\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\",true],[10,\"src\",\"/loader.png\"],[10,\"alt\",\"\"],[10,\"class\",\"loader block w-4\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"svg-jar\",[\"arrow-right\"],[[\"class\"],[\"fill-current block w-4\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/login.hbs"
    }
  });

  _exports.default = _default;
});