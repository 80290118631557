define("console/components/ui-input", ["exports", "imask"], function (_exports, _imask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    type: 'text',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.mask) {
        return;
      }

      var input = document.getElementById(this.id);
      this.imask = new _imask.default(input, {
        mask: this.mask
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.imask && this.imask.destroy();
    }
  });

  _exports.default = _default;
});