define("console/templates/shops/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gkp5yEGk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"shop\",\"title\"]]],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[1,[28,\"shop-editor\",null,[[\"shop\",\"onSave\",\"close\"],[[24,[\"shop\"]],[28,\"transition-to\",[\"shops\"],null],[28,\"transition-to\",[\"shops\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/shops/shop.hbs"
    }
  });

  _exports.default = _default;
});