define("console/models/manager", ["exports", "console/models/user", "ember-data", "moment"], function (_exports, _user, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _user.default.extend({
    checkin: attr('date'),
    checkout: attr('date'),
    location: belongsTo('location'),
    locationId: Ember.computed.readOnly('location.id'),
    isAdmin: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('admin');
    }),
    isMedic: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('medic');
    }),
    isRestricted: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('restricted');
    }),
    isAtLocation: Ember.computed('checkin', 'checkout', function () {
      return (0, _moment.default)(this.checkin).isAfter(this.checkout) || this.checkin && !this.checkout;
    })
  });

  _exports.default = _default;
});