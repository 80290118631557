define("console/templates/components/shop-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M/A/mAlN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"lg\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"shop-editor/header\",null,[[\"shop\",\"close\",\"save\"],[[24,[\"shop\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"shop-editor/fields\",null,[[\"shop\"],[[24,[\"shop\"]]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"w-1/2 px-4 mb-8 sm:w-full\"],[8],[0,\"\\n        \"],[1,[28,\"address-section\",null,[[\"title\",\"address\",\"showCompanyFields\",\"parent\"],[\"Contact Address\",[24,[\"shop\",\"contactAddress\"]],true,[24,[\"shop\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"w-1/2 px-4 mb-8 sm:w-full\"],[8],[0,\"\\n        \"],[1,[28,\"address-section\",null,[[\"title\",\"address\",\"parent\",\"showCompanyFields\"],[\"Billing Address\",[24,[\"shop\",\"billingAddress\"]],[24,[\"shop\"]],true]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/shop-editor.hbs"
    }
  });

  _exports.default = _default;
});