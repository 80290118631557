define("console/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c/w7afbh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"block print:hidden\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"wormhole\"],[8],[9],[0,\"\\n  \"],[1,[22,\"flash-messages\"],false],[0,\"\\n  \"],[1,[22,\"connection-status\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"print-wormhole\"],[10,\"class\",\"hidden print:block\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/application.hbs"
    }
  });

  _exports.default = _default;
});