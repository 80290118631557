define("console/templates/components/images-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URc2gkRY",
    "block": "{\"symbols\":[\"image\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedImages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"w-1/\",[22,\"columns\"],\" px-4 mb-8 sm:w-1/2\"]]],[8],[0,\"\\n      \"],[1,[28,\"images-section/image\",null,[[\"image\",\"onDragStart\",\"onDragEnd\",\"onDragOver\",\"onDrop\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"draggedImage\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"draggedImage\"]]],null],null],null],[28,\"action\",[[23,0,[]],\"onDragOver\"],null],[28,\"action\",[[23,0,[]],\"onDrop\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"inline-block relative overflow-hidden h-6\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/loader.png\"],[10,\"alt\",\"\"],[10,\"class\",\"loader block w-6\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"file-field\",null,[[\"class\",\"id\",\"multiple\",\"filesDidChange\"],[\"absolute opacity-0 w-px h-px pointer-events-none\",\"images-upload\",true,[28,\"action\",[[23,0,[]],\"uploadImages\"],null]]]],false],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"images-upload\"],[8],[7,\"a\",true],[8],[0,\"Upload Images\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/images-section.hbs"
    }
  });

  _exports.default = _default;
});