define("console/templates/pos/order/charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YIUF7YK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mx-auto my-12 text-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"inline-block text-green mb-2\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"sumup\"],[[\"width\",\"height\",\"class\"],[\"36\",\"36\",\"fill-current inline-block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-green\"],[8],[0,\"\\n    You will now be\"],[7,\"br\",true],[8],[9],[0,\" redirected to SumUp.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/pos/order/charge.hbs"
    }
  });

  _exports.default = _default;
});