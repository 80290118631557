define("console/templates/components/order-cancellation/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5QCebt8f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center bg-grey-lightest p-8 rounded-t sm:rounded-none sm:p-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-auto flex items-center\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-blue p-2 bg-grey-lighter rounded-full hidden sm:block\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"arrow-left\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"text-xl font-normal sm:hidden\"],[8],[0,\"\\n      Cancel #\"],[1,[24,[\"order\",\"number\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--red ml-2\"],[3,\"action\",[[23,0,[]],[24,[\"markAsCanceled\"]]]],[8],[0,\"\\n      \"],[1,[28,\"if\",[[24,[\"order\",\"isSaving\"]],\"Saving...\",\"Cancel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-cancellation/header.hbs"
    }
  });

  _exports.default = _default;
});