define("console/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    modules: Ember.A(['Appointments', 'POS', 'Orders', 'Customers', 'Products', 'Categories', 'Discounts', 'Locations', 'Lab', 'Shops', 'Managers', 'Invoices']),
    assistantModules: Ember.A(['Appointments', 'POS', 'Orders', 'Sales'])
  });

  _exports.default = _default;
});