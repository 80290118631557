define("console/templates/components/category-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IRx8lGJT",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"table w-full\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"table-row text-sm font-medium\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-cell py-4 text-left\"],[8],[0,\"Title\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-cell py-4 text-right\"],[8],[0,\"Updated\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"categories\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"table-row text-black no-underline hover:bg-grey-lightest\",\"categories.category\",[23,1,[]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"table-cell border-t border-gray py-4 text-left\"],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"table-cell border-t border-gray py-4 text-right\"],[8],[0,\"\\n        \"],[1,[28,\"format-relative\",[[23,1,[\"updatedAt\"]]],[[\"allowEmpty\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/category-list.hbs"
    }
  });

  _exports.default = _default;
});