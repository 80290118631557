define("console/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Rp1rZI3",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[[28,\"concat\",[\"Hi \",[24,[\"session\",\"manager\",\"firstName\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-2\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"if\",[[24,[\"session\",\"manager\",\"isAdmin\"]],[24,[\"modules\"]],[24,[\"assistantModules\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"px-2 w-1/4 sm:w-1/2 mb-4 text-center text-lg sm:text-base\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"block py-12 sm:py-6 no-underline\\n          border border-grey-lighter hover:shadow rounded-sm text-black\",[28,\"lowercase\",[[23,1,[]]],null]]],{\"statements\":[[0,\"          \"],[1,[28,\"svg-jar\",[[28,\"lowercase\",[[23,1,[]]],null]],[[\"class\"],[\"block mx-auto mb-2 h-8\"]]],false],[0,\"\\n          \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/index.hbs"
    }
  });

  _exports.default = _default;
});