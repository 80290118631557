define("console/templates/components/item-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vq5N0lcP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center justify-end\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"item\",\"order\",\"isPrinted\"]],\"text-white bg-green\",\"text-grey bg-grey-lightest\"],null],\" rounded-full p-1 mr-3\"]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"warehouse\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"item\",\"receivedToLabAt\"]],[28,\"if\",[[24,[\"item\",\"assembledAt\"]],\"text-white bg-green\",\"text-white bg-yellow\"],null],\"text-grey bg-grey-lightest\"],null],\" rounded-full p-1 mr-3\"]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"assembly\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"item\",\"order\",\"fulfilledAt\"]],\"text-white bg-green\",\"text-grey bg-grey-lightest\"],null],\" rounded-full p-1\"]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"customer\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/item-status.hbs"
    }
  });

  _exports.default = _default;
});