define("console/templates/components/barcode-scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "chMmyanA",
    "block": "{\"symbols\":[\"device\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed pin z-40 bg-smoke overflow-auto scrolling-touch\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full min-h-full flex flex-col justify-end\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-col shadow-lg bg-black\"],[8],[0,\"\\n      \"],[7,\"video\",true],[11,\"id\",[22,\"videoId\"]],[10,\"class\",\"block w-full min-h-screen\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"absolute flex flex-col justify-between items-center pin p-8 z-10\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-between\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"devices\"]]],null,{\"statements\":[[0,\"            \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"no-underline p-3\\n              \",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"currentDevice\"]]],null],\"text-white\",\"text-grey\"],null]]]],[3,\"action\",[[23,0,[]],\"changeDevice\",[23,1,[]]]],[8],[0,\"\\n              \"],[1,[23,1,[\"label\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"w-full h-2px bg-yellow\"],[8],[9],[0,\"\\n        \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-white p-4 bg-red rounded-full shadow-lg\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"close\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/barcode-scanner.hbs"
    }
  });

  _exports.default = _default;
});