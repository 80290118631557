define("console/templates/components/order-cancellation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yGiqtm0A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"sm\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"order-cancellation/header\",null,[[\"order\",\"close\",\"markAsCanceled\"],[[24,[\"order\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"markAsCanceled\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"order-cancellation/fields\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-cancellation.hbs"
    }
  });

  _exports.default = _default;
});