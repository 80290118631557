define("console/templates/components/category-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GEo4DGkV",
    "block": "{\"symbols\":[\"key\",\"value\",\"key\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full px-4 mb-8\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input\",null,[[\"id\",\"label\",\"value\",\"errors\",\"oninput\"],[\"category-title\",\"Title\",[24,[\"category\",\"title\"]],[24,[\"category\",\"errors\",\"title\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"category\",\"title\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"category\",\"surcharges\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-1/2 px-4 mb-8\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input\",null,[[\"type\",\"id\",\"label\",\"value\",\"oninput\"],[\"number\",[28,\"concat\",[\"category-\",[28,\"camelize\",[[23,3,[]]],null]],null],[28,\"humanize\",[[23,3,[]]],null],[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[28,\"get\",[[24,[\"category\",\"surcharges\"]],[23,3,[]]],null]],null]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[4,\"each\",[[28,\"array\",[\"materials\",\"shapes\",\"widths\",\"lensColors\"],null]],null,{\"statements\":[[4,\"if\",[[28,\"get\",[[24,[\"category\"]],[23,1,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"w-full px-4 mb-8\"],[8],[0,\"\\n        \"],[1,[28,\"ui-autocomplete\",null,[[\"id\",\"label\",\"selection\",\"errors\"],[[28,\"concat\",[\"category-\",[23,1,[]]],null],[28,\"humanize\",[[23,1,[]]],null],[28,\"get\",[[24,[\"category\"]],[23,1,[]]],null],[28,\"get\",[[24,[\"category\",\"errors\"]],[23,1,[]]],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/category-editor/fields.hbs"
    }
  });

  _exports.default = _default;
});