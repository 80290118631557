define("console/templates/components/marketing-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "puM+Rthi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"uppercase font-normal text-xs tracking-wide border-b py-4\"],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"py-3 flex flex-wrap text-xxs\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"utmSource\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"block bg-grey-lightest px-2 py-1 mr-2 mb-2 rounded-full\"],[8],[1,[24,[\"model\",\"utmMedium\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"block bg-grey-lightest px-2 py-1 mr-2 mb-2 rounded-full\"],[8],[1,[24,[\"model\",\"utmCampaign\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"block bg-grey-lightest px-2 py-1 mr-2 mb-2 rounded-full\"],[8],[1,[24,[\"model\",\"utmTerm\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"block bg-grey-lightest px-2 py-1 mr-2 mb-2 rounded-full\"],[8],[1,[24,[\"model\",\"utmContent\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"block bg-grey-lightest px-2 py-1 mr-2 mb-2 rounded-full\"],[8],[0,\"Direct\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/marketing-section.hbs"
    }
  });

  _exports.default = _default;
});