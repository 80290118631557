define("console/templates/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7CYfGLO9",
    "block": "{\"symbols\":[\"order\",\"monthOption\"],\"statements\":[[1,[28,\"page-title\",[[28,\"concat\",[\"Invoices \",[24,[\"orders\",\"firstObject\",\"invoiceNumber\"]],\" - \",[24,[\"orders\",\"lastObject\",\"invoiceNumber\"]]],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Invoices\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center mb-8\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tabs flex-auto\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"monthOptions\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"activeClass\",\"query\",\"route\"],[\"tabs__tab\",\"tabs__tab--active\",[28,\"hash\",null,[[\"month\",\"page\"],[[23,2,[]],1]]],\"invoices\"]],{\"statements\":[[0,\"          \"],[1,[28,\"humanize\",[[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"btn btn--sm btn--grey ml-6\"],[3,\"action\",[[23,0,[]],\"print\"]],[8],[0,\"\\n      Print\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"unless\",[[24,[\"isLoaded\"]],\"opacity-25\"],null]],[8],[0,\"\\n    \"],[1,[28,\"invoice-list\",null,[[\"orders\",\"class\"],[[24,[\"orders\"]],\"mb-8\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-pagination\",null,[[\"pagination\",\"change\"],[[24,[\"orders\",\"meta\",\"pagination\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"page\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"orders\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"print-wormhole\"]],{\"statements\":[[0,\"    \"],[1,[28,\"order-invoice\",null,[[\"order\",\"locale\",\"autoPrint\"],[[23,1,[]],\"ro\",false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/invoices.hbs"
    }
  });

  _exports.default = _default;
});