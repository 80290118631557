define("console/components/breaks-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timeOptions: Ember.computed(function () {
      var options = [];

      for (var h = 0; h <= 24; h++) {
        options.push("".concat(h, ":00").padStart(5, '0'));
      }

      return options;
    })
  });

  _exports.default = _default;
});