define("console/templates/appointments/appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mjTp+ej8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"appointment\",\"customer\",\"fullName\"]]],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[1,[28,\"appointment-editor\",null,[[\"appointment\",\"onSave\",\"onDelete\",\"close\"],[[24,[\"appointment\"]],[28,\"transition-to\",[\"appointments\"],null],[28,\"transition-to\",[\"appointments\"],null],[28,\"transition-to\",[\"appointments\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/appointments/appointment.hbs"
    }
  });

  _exports.default = _default;
});