define("console/controllers/managers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    search: '',
    managers: Ember.computed.readOnly('model'),
    managersSorting: Ember.A(['isActive:desc', 'firstName:asc']),
    sortedManagers: Ember.computed.sort('filteredManager', 'managersSorting'),
    filteredManager: Ember.computed('managers.[]', 'search', function () {
      var search = new RegExp(this.search, 'i');
      return (this.managers || []).filter(function (manager) {
        return search.test(manager.fullName);
      });
    })
  });

  _exports.default = _default;
});