define("console/routes/lab", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading"], function (_exports, _authenticatedRouteMixin, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    queryParams: {
      status: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var status = _ref.status,
          page = _ref.page;
      var assembled = status == 'assembled';
      var sort = assembled ? '-assembled_at' : 'order_printed_at';
      return this.store.query('item', {
        filter: {
          assembled_at_null: !assembled,
          order_paid_at_null: false,
          order_printed_at_null: false,
          order_fulfilled_at_null: true,
          order_canceled_at_null: true,
          order_archived_at_null: true
        },
        page: {
          number: page,
          size: 100
        },
        sort: sort
      });
    }
  });

  _exports.default = _default;
});