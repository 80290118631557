define("console/components/item-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('categories', this.store.findAll('category'));
      this.set('products', this.store.findAll('product'));
    },
    store: Ember.inject.service(),
    product: Ember.computed.alias('item.product'),
    surcharges: Ember.computed.alias('item.surcharges'),
    category: Ember.computed.reads('product.category'),
    productOptions: Ember.computed('products.[]', 'category', function () {
      return this.products.filterBy('category.id', this.get('category.id')).sortBy('title');
    }),
    actions: {
      changeCategory: function changeCategory(category) {
        this.set('category', category);
        this.set('product', null);
        this.surcharges.clear();
      },
      toggleSurcharge: function toggleSurcharge(option) {
        if (this.surcharges.includes(option)) {
          return this.surcharges.removeObject(option);
        }

        this.surcharges.addObject(option);
      }
    }
  });

  _exports.default = _default;
});