define("console/templates/components/lab-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P44YQcBS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"item\",\"order\",\"canceledAt\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label label--red\"],[8],[0,\"\\n    canceled\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"item\",\"assembledAt\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label label--green\"],[8],[0,\"\\n    assembled\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"item\",\"receivedToLabAt\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label label--yellow\"],[8],[0,\"\\n    scanned\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label label--blue\"],[8],[0,\"\\n    new\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/lab-status-label.hbs"
    }
  });

  _exports.default = _default;
});