define("console/components/ui-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['relative'],
    isActive: false,
    didInsertElement: function didInsertElement() {
      var id = this.element.id;
      (0, _jquery.default)(document).on("mousedown.".concat(id, " touchstart.").concat(id), this.documentClick.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      var id = this.element.id;
      (0, _jquery.default)(document).off("mousedown.".concat(id, " touchstart.").concat(id));
    },
    documentClick: function documentClick(event) {
      var element = this.element;

      if (element && !_jquery.default.contains(element, event.target)) {
        this.set('isActive', false);
      }
    },
    click: function click() {
      this.set('isActive', true);
    }
  });

  _exports.default = _default;
});