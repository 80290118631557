define("console/templates/components/order-pick-up/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kbn4LBoQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full px-4\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input\",null,[[\"type\",\"id\",\"label\",\"value\",\"oninput\"],[\"date\",\"pickup-date\",\"Date\",[24,[\"pickupAt\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pickupAt\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"order\",\"returnPickupAt\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-full px-4 mt-8\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-grey-lightest rounded p-4\"],[8],[0,\"\\n        Pick-up scheduled\\n        \"],[1,[28,\"format-relative\",[[24,[\"order\",\"returnPickupAt\"]]],null],false],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"float-right\"],[8],[0,\"\\n          \"],[1,[28,\"format-date\",[[24,[\"order\",\"returnPickupAt\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-pick-up/fields.hbs"
    }
  });

  _exports.default = _default;
});