define("console/templates/components/list-sort-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2QROXcBI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"if\",[[24,[\"label\"]],[24,[\"label\"]],[28,\"humanize\",[[24,[\"value\"]]],null]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isCurrent\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"svg-jar\",[[28,\"if\",[[24,[\"sortDesc\"]],\"chevron-down\",\"chevron-up\"],null]],[[\"width\",\"height\",\"class\"],[\"14\",\"14\",\"fill-current inline-block ml-1\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/list-sort-btn.hbs"
    }
  });

  _exports.default = _default;
});