define("console/templates/components/location-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XBE6Wud/",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"uppercase font-normal text-xs tracking-wide border-b py-4\"],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"locations\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"flex items-center py-3\\n    border-b border-grey-lighter cursor-pointer \",[28,\"if\",[[28,\"eq\",[[24,[\"parent\",\"location\",\"id\"]],[23,1,[\"id\"]]],null],\"text-blue\"],null]]]],[3,\"action\",[[23,0,[]],\"changeLocation\",[23,1,[]]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[[28,\"concat\",[\"radiobox-\",[28,\"if\",[[28,\"eq\",[[24,[\"parent\",\"location\",\"id\"]],[23,1,[\"id\"]]],null],\"marked\",\"blank\"],null]],null]],[[\"width\",\"height\",\"class\"],[\"21\",\"21\",\"fill-current block mr-2\"]]],false],[0,\"\\n    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-auto text-right text-grey\"],[8],[0,\"\\n      \"],[1,[23,1,[\"address\",\"city\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/location-section.hbs"
    }
  });

  _exports.default = _default;
});