define("console/routes/appointments", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading", "moment"], function (_exports, _authenticatedRouteMixin, _loading, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    session: Ember.inject.service(),
    queryParams: {
      interval: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      all: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var interval = _ref.interval,
          page = _ref.page,
          all = _ref.all;
      var filter = {};
      var today = (0, _moment.default)().format(DATE_FORMAT);
      var tomorrow = (0, _moment.default)().add(1, 'day').format(DATE_FORMAT);

      if (interval == 'today') {
        filter.starts_at_gt = today;
        filter.starts_at_lt = tomorrow;
      } else if (interval == 'future') {
        filter.starts_at_gt = tomorrow;
      } else if (interval == 'past') {
        filter.starts_at_lt = today;
      }

      if (!all) {
        filter.location_id_eq = this.session.manager.locationId;
      }

      return this.store.query('appointment', {
        filter: filter,
        sort: interval == 'past' ? '-starts_at' : 'starts_at',
        include: 'customer',
        page: {
          number: page,
          size: 100
        }
      });
    }
  });

  _exports.default = _default;
});