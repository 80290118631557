define("console/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    notes: attr('string'),
    locale: attr('string', {
      defaultValue: 'en'
    }),
    lastLoginAt: attr('date'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var names = [this.firstName, this.lastName].filter(function (n) {
        return Ember.isPresent(n);
      });

      if (Ember.isPresent(names)) {
        return names.join(' ');
      }
    }),
    isActive: Ember.computed('email', function () {
      return !/_deactivated$/.test(this.email);
    })
  });

  _exports.default = _default;
});