define("console/routes/orders/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, order) {
      this._super.apply(this, arguments);

      this.controllerFor('orders').set('lastOrder', order);
    }
  });

  _exports.default = _default;
});