define("console/templates/products/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+FBwRSIb",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"product-editor\",null,[[\"product\",\"onSave\",\"close\"],[[24,[\"product\"]],[28,\"queue\",[[28,\"refresh-route\",[\"products\"],null],[28,\"transition-to\",[\"products.product\",[24,[\"product\"]]],null]],null],[28,\"transition-to\",[\"products\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/products/new.hbs"
    }
  });

  _exports.default = _default;
});