define("console/templates/components/location-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qGVXYS/k",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"locations\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-1/3 px-2 mb-4 sm:w-full\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"block relative border rounded text-black no-underline hover:shadow\",\"locations.location\",[23,1,[]]]],{\"statements\":[[0,\"        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"image\",\"mdUrl\"]]],[10,\"alt\",\"\"],[10,\"class\",\"block w-full rounded-t\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"p-4\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[10,\"class\",\"text-base font-normal\"],[8],[0,\"\\n            \"],[1,[23,1,[\"title\"]],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"text-grey\"],[8],[0,\"\\n            \"],[1,[23,1,[\"address\",\"city\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/location-list.hbs"
    }
  });

  _exports.default = _default;
});