define("console/templates/components/location-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EsEXsY2g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"md\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"location-editor/header\",null,[[\"location\",\"close\",\"showBreaks\",\"save\"],[[24,[\"location\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showBreaksEditor\"]]],null],true],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"location-editor/fields\",null,[[\"location\"],[[24,[\"location\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"product\",\"isNew\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"images-section\",null,[[\"target\",\"images\",\"columns\"],[[24,[\"location\"]],[24,[\"location\",\"images\"]],3]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pt-4 px-8 pb-8 bg-grey-lightest rounded-b\"],[8],[0,\"\\n    \"],[1,[28,\"address-section\",null,[[\"address\",\"showCompanyFields\",\"parent\"],[[24,[\"location\",\"address\"]],true,[24,[\"location\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showBreaksEditor\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"wormhole\"]],{\"statements\":[[0,\"    \"],[1,[28,\"breaks-editor\",null,[[\"location\",\"close\"],[[24,[\"location\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showBreaksEditor\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/location-editor.hbs"
    }
  });

  _exports.default = _default;
});