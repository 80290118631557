define("console/components/prescription-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    title: 'Prescription',
    editable: true,
    showSphere: true,
    showCylinder: true,
    showAxis: true,
    showAdd: true,
    showSegmentHeight: Ember.computed.readOnly('prescription.hasSegmentHeight'),
    showPrism: Ember.computed.readOnly('prescription.hasPrism'),
    showPupilDistance: true,
    actions: {
      addPrescription: function addPrescription() {
        this.set('prescription', this.store.createRecord('prescription'));
      }
    }
  });

  _exports.default = _default;
});