define("console/templates/pos/order/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j3l2IS3p",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pos-products\",null,[[\"categories\",\"products\",\"order\",\"category\",\"addItem\"],[[24,[\"categories\"]],[24,[\"products\"]],[24,[\"order\"]],[24,[\"category\"]],[28,\"action\",[[23,0,[]],\"addItem\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"cartIsVisible\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"pos-cart\",null,[[\"order\",\"isLoading\",\"removeItem\",\"updateItem\",\"charge\",\"applyDiscount\",\"close\"],[[24,[\"order\"]],[24,[\"isLoading\"]],[28,\"action\",[[23,0,[]],\"removeItem\"],null],[28,\"action\",[[23,0,[]],\"updateItem\"],null],[28,\"action\",[[23,0,[]],\"charge\"],null],[28,\"action\",[[23,0,[]],\"applyDiscount\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"cartIsVisible\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"order\",\"hasItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"fixed flex justify-center pin-l pin-r pin-b z-10 p-8 pointer-events-none\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-white p-4 bg-green rounded-full shadow-lg pointer-events-auto\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"cartIsVisible\"]]],null],true]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"cart-plus\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/pos/order/index.hbs"
    }
  });

  _exports.default = _default;
});