define("console/routes/orders", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "console/mixins/routes/loading"], function (_exports, _authenticatedRouteMixin, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Object.assign;
  var STATUS_FILTERS = {
    open: {
      filter: {
        paid_at_null: false,
        printed_at_null: true,
        fulfilled_at_null: true,
        canceled_at_null: true,
        archived_at_null: true
      },
      sort: 'paid_at'
    },
    printed: {
      filter: {
        paid_at_null: false,
        printed_at_null: false,
        fulfilled_at_null: true,
        canceled_at_null: true,
        archived_at_null: true
      },
      sort: 'printed_at'
    },
    fulfilled: {
      filter: {
        fulfilled_at_null: false,
        canceled_at_null: true,
        archived_at_null: true,
        returned_at_null: true
      },
      sort: 'fulfilled_at'
    },
    returned: {
      filter: {
        returned_at_null: false,
        archived_at_null: true
      },
      sort: '-returned_at'
    },
    archived: {
      filter: {
        archived_at_null: false
      },
      sort: '-archived_at'
    },
    abandoned: {
      filter: {
        paid_at_null: true,
        items_hto_eq: false,
        shipping_address_id_null: false
      },
      sort: '-updated_at'
    }
  };

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loading.default, {
    queryParams: {
      search: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      tag: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var search = _ref.search,
          status = _ref.status,
          page = _ref.page,
          tag = _ref.tag;
      var params = assign({}, STATUS_FILTERS[status]);
      params.page = {
        number: page
      };

      if (!Ember.isEmpty(search)) {
        params.filter = {
          number_eq: search
        };
      } else if (!Ember.isEmpty(tag)) {
        params.filter = {
          tags_contains_array: tag
        };
      } else if (!this.session.manager.isAdmin) {
        params.filter.location_id_eq = this.session.manager.locationId;
      }

      return this.store.query('order', params);
    }
  });

  _exports.default = _default;
});