define("console/templates/categories/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3UN/eoc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"category\",\"title\"]]],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[1,[28,\"category-editor\",null,[[\"category\",\"onSave\",\"close\"],[[24,[\"category\"]],[28,\"transition-to\",[\"categories\"],null],[28,\"transition-to\",[\"categories\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/categories/category.hbs"
    }
  });

  _exports.default = _default;
});