define("console/mixins/routes/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      this.set('isActive', true);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.set('isActive', false);
    },
    actions: {
      loading: function loading(transition) {
        var _this = this;

        this._super.apply(this, arguments);

        if (this.controller) {
          this.controller.set('isLoaded', false);
        }

        transition.finally(function () {
          if (_this.controller) {
            _this.controller.set('isLoaded', true);
          }
        });
        return !this.isActive;
      }
    }
  });

  _exports.default = _default;
});