define("console/templates/components/order-cancellation/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "va+a8Q83",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-8\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input\",null,[[\"type\",\"id\",\"label\",\"value\",\"errors\",\"oninput\"],[\"number\",\"order-refundAmount\",\"Refund Amount\",[24,[\"order\",\"refundAmount\"]],[24,[\"order\",\"errors\",\"refundAmount\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"order\",\"refundAmount\"]]],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[0,\"\\n  \"],[1,[28,\"ui-checkbox\",null,[[\"id\",\"label\",\"checked\",\"onclick\"],[\"order-skipCancelationEmail\",\"Don't send cancelation email to customer\",[24,[\"order\",\"skipCancelationEmail\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"order\",\"skipCancelationEmail\"]]],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-cancellation/fields.hbs"
    }
  });

  _exports.default = _default;
});