define("console/templates/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uatBGJM+",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Customers\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Customers\"]]],false],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[11,\"class\",[28,\"unless\",[[24,[\"isLoaded\"]],\"opacity-25\"],null]],[8],[0,\"\\n    \"],[1,[28,\"customer-list\",null,[[\"customers\",\"sort\",\"changeSort\",\"class\"],[[24,[\"customers\"]],[24,[\"sort\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sort\"]]],null]],null],\"mb-8\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-pagination\",null,[[\"pagination\",\"change\"],[[24,[\"customers\",\"meta\",\"pagination\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"page\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/customers.hbs"
    }
  });

  _exports.default = _default;
});