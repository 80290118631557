define("console/routes/pos/order/charge", ["exports", "console/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    afterModel: function afterModel(_ref) {
      var isPaid = _ref.isPaid;

      this._super.apply(this, arguments);

      if (isPaid) {
        this.transitionTo('pos');
      }
    },
    redirect: function redirect(_ref2) {
      var id = _ref2.id,
          number = _ref2.number,
          currency = _ref2.currency,
          total = _ref2.total;

      var params = _jquery.default.param({
        'affiliate-key': _environment.default.sumup.affiliateKey,
        'app-id': _environment.default.sumup.appId,
        'skip-screen-success': true,
        callbacksuccess: window.location.origin + this.router.urlFor('pos.order.complete', id),
        callbackfail: window.location.origin + this.router.urlFor('pos.order', id),
        title: "#".concat(number),
        currency: currency,
        amount: total
      });

      window.location.replace("sumupmerchant://pay/1.0?".concat(params));
    }
  });

  _exports.default = _default;
});