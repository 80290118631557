define("console/initializers/ember-cli-mirage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-cli-mirage',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});