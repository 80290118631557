define("console/templates/components/pos-cart/discount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hOAl/68I",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"order\",\"hasDiscount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"pb-8 mb-8 border-b border-grey-lighter flex justify-between text-sm mb-8\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"font-medium\"],[8],[0,\"Discount (\"],[1,[24,[\"order\",\"discount\",\"code\"]],false],[0,\")\"],[9],[0,\"\\n    -\"],[1,[28,\"format-number\",[[24,[\"order\",\"discountPrice\"]]],[[\"format\",\"locale\"],[[24,[\"order\",\"currency\"]],[24,[\"order\",\"shop\",\"locale\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"pb-8 mb-8 border-b border-grey-lighter flex items-center justify-between\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"class\",\"block appearance-none rounded-none bg-transparent w-full\\n    text-black leading-normal text-lg p-0 outline-none\"],[11,\"value\",[22,\"code\"]],[10,\"id\",\"order-discountCode\"],[10,\"placeholder\",\"Voucher\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"code\"]]],null]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-lg ml-4 no-underline text-blue\"],[3,\"action\",[[23,0,[]],[24,[\"apply\"]],[24,[\"code\"]]]],[8],[0,\"\\n    Apply\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/pos-cart/discount.hbs"
    }
  });

  _exports.default = _default;
});