define("console/templates/managers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0yTnmOTl",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"manager-editor\",null,[[\"manager\",\"onSave\",\"close\"],[[24,[\"manager\"]],[28,\"queue\",[[28,\"refresh-route\",[\"managers\"],null],[28,\"transition-to\",[\"managers.manager\",[24,[\"manager\"]]],null]],null],[28,\"transition-to\",[\"managers\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/managers/new.hbs"
    }
  });

  _exports.default = _default;
});