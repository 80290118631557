define("console/templates/components/order-print-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uj66XjkB",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"order\",\"regularItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"page-break-after\"],[8],[0,\"\\n    \"],[1,[28,\"order-print-preview/item\",null,[[\"item\",\"items\",\"index\"],[[23,1,[]],[24,[\"order\",\"regularItems\"]],[23,2,[]]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"needsPrescription\"]],[24,[\"order\",\"hasPrescription\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"order-print-preview/prescription\",null,[[\"prescription\"],[[24,[\"order\",\"prescription\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/order-print-preview.hbs"
    }
  });

  _exports.default = _default;
});