define("console/components/connection-status", ["exports", "console/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isVisible: false,
    init: function init() {
      if (_environment.default.environment != 'test') {
        this.checkConnection();
      }

      this._super.apply(this, arguments);
    },
    checkConnection: function checkConnection() {
      var _this = this;

      this.set('isVisible', !window.navigator.onLine);
      Ember.run.later(function () {
        _this.checkConnection();
      }, 200);
    }
  });

  _exports.default = _default;
});