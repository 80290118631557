define("console/models/item", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    price: attr('number', {
      defaultValue: 0
    }),
    quantity: attr('number', {
      defaultValue: 1
    }),
    vatAmount: attr('number', {
      defaultValue: 0
    }),
    productSku: attr('string'),
    surcharges: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hto: attr('boolean', {
      defaultValue: false
    }),
    vatRate: attr('number', {
      defaultValue: 0
    }),
    labGid: attr('string'),
    lens: attr('string'),
    alternativeLens: attr('string'),
    lensColor: attr('string'),
    nearVision: attr('boolean'),
    requestedLabAt: attr('date'),
    sentToLabAt: attr('date'),
    arrivedToLabAt: attr('date'),
    receivedToLabAt: attr('date'),
    assembledAt: attr('date'),
    requestedLabReturnAt: attr('date'),
    sentFromLabAt: attr('date'),
    receivedFromLabAt: attr('date'),
    fulfilledAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    invoiceTitle: attr('string'),
    shop: belongsTo('shop'),
    order: belongsTo('order'),
    product: belongsTo('product'),
    receiptTitle: Ember.computed.readOnly('product.fullTitle'),
    isGlasses: Ember.computed.readOnly('product.isGlasses'),
    isSunglasses: Ember.computed.readOnly('product.isSunglasses'),
    isClipOn: Ember.computed.readOnly('product.isClipOn'),
    isFulfilled: Ember.computed.bool('fulfilledAt'),
    hasSurcharges: Ember.computed.notEmpty('surcharges'),
    vat: Ember.computed('price', 'vatRate', function () {
      return this.price - this.price / (1 + this.vatRate / 100);
    }),
    total: Ember.computed('price', 'quantity', function () {
      return this.price * this.quantity;
    }),
    totalVat: Ember.computed('vat', 'quantity', function () {
      return this.vat * this.quantity;
    }),
    hasBlueLightFilter: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('blue_light_filter');
    }),
    hasLightResponsiveFilter: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('light_responsive_filter');
    }),
    hasPolarizingFilter: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('polarizing_filter');
    }),
    hasPremium: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('high_index');
    }),
    needsProgressiveLenses: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('progressive_lenses');
    }),
    needsDegressiveLenses: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('degressive_lenses');
    }),
    needsPrescription: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).some(function (key) {
        return /_lenses$/.test(key);
      });
    }),
    isSingleVision: Ember.computed('surcharges.[]', function () {
      return (this.surcharges || []).includes('single_vision_lenses');
    }),
    underWarranty: Ember.computed('order.paidAt', function () {
      return this.get('order.paidAt') && (0, _moment.default)(this.get('order.paidAt')).add(2, 'years').isAfter((0, _moment.default)());
    }),
    delayedShipping: Ember.computed('surcharges.[]', 'isSunglasses', function () {
      return this.needsProgressiveLenses || this.needsDegressiveLenses || this.hasPremium || this.hasPolarizingFilter || this.hasLightResponsiveFilter || this.isSunglasses && this.needsPrescription;
    })
  });

  _exports.default = _default;
});