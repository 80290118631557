define("console/components/ui-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    query: '',
    update: function update() {},
    filteredOptions: Ember.computed('options.[]', 'selection.[]', 'query', function () {
      var search = new RegExp(this.query, 'i');
      var selection = this.selection || [];
      return (this.options || []).filter(function (value) {
        return search.test(value) && selection.indexOf(value) === -1;
      });
    }),
    actions: {
      search: function search(query) {
        this.set('query', query);
      },
      handleKeyPress: function handleKeyPress(event) {
        var value = this.query;
        var key = event.keyCode;

        if (key === 13 && value) {
          this.send('addOption', value);
          this.set('query', '');
        }
      },
      addOption: function addOption(value) {
        this.selection.addObject(value);
        this.update(this.selection);
      },
      removeOption: function removeOption(value) {
        this.selection.removeObject(value);
        this.update(this.selection);
      }
    }
  });

  _exports.default = _default;
});