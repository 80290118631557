define("console/templates/components/ui-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/c07/00",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[29,[\"block uppercase tracking-wide text-xs font-medium mb-2 \",[28,\"if\",[[24,[\"errors\"]],\"text-red\",\"text-grey\"],null]]]],[11,\"for\",[22,\"id\"]],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"textarea\",true],[11,\"class\",[29,[\"block appearance-none rounded-none border-b bg-transparent w-full text-black\\n  leading-normal text-lg p-0 py-2 outline-none \",[28,\"if\",[[24,[\"errors\"]],\"border-red\",\"focus:border-blue\"],null]]]],[11,\"id\",[22,\"id\"]],[11,\"readonly\",[28,\"if\",[[24,[\"readonly\"]],\"readonly\"],null]],[11,\"autofocus\",[22,\"autofocus\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"rows\",[22,\"rows\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"oninput\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"onblur\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"onblur\"]]],null]],[[\"value\"],[\"target.value\"]]]],[8],[1,[22,\"value\"],false],[9],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-textarea.hbs"
    }
  });

  _exports.default = _default;
});