define("console/templates/components/file-upload-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0uKj9QR3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"for\",\"file-upload\"],[10,\"role\",\"button\"],[10,\"class\",\"block relative border\\n  border-blue font-medium rounded-full py-3 text-blue\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"src\",\"/loader.png\"],[10,\"alt\",\"\"],[10,\"class\",\"loader w-4 mr-2\"],[8],[9],[0,\"\\n      Uploading...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"file-field\",null,[[\"id\",\"class\",\"filesDidChange\"],[\"file-upload\",\"absolute opacity-0 w-px h-px pointer-events-none\",[28,\"action\",[[23,0,[]],[24,[\"upload\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/file-upload-btn.hbs"
    }
  });

  _exports.default = _default;
});