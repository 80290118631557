define("console/components/order-editor/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOG_ROCKET_URL = 'https://app.logrocket.com/7udia2/lunet/sessions';

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    logRocketURL: Ember.computed('order', function () {
      var filters = encodeURIComponent(JSON.stringify([{
        type: 'userID',
        operator: {
          name: 'is',
          type: 'IS',
          hasStrings: true
        },
        strings: [this.get('order.customer.id')]
      }]));
      return "".concat(LOG_ROCKET_URL, "?filters=").concat(filters);
    })
  });

  _exports.default = _default;
});