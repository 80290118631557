define("console/components/prescription-editor/fields", ["exports", "console/utils/options-range"], function (_exports, _optionsRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sphereOptions: (0, _optionsRange.default)(-15, 15, 0.25, '0.00'),
    addOptions: (0, _optionsRange.default)(0, 4, 0.25, '0.00'),
    cylinderOptions: (0, _optionsRange.default)(-6, 6, 0.25, '0.00')
  });

  _exports.default = _default;
});