define("console/components/discount-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    shopsSorting: Ember.A(['title:asc']),
    categoriesSorting: Ember.A(['position:asc']),
    sortedShops: Ember.computed.sort('shops', 'shopsSorting'),
    sortedCategories: Ember.computed.sort('categories', 'categoriesSorting'),
    surchargeOptions: Ember.computed('categories.[]', function () {
      var surcharges = this.categories.map(function (c) {
        return keys(c.surcharges);
      }).flat();
      return surcharges.filter(function (value, index) {
        return surcharges.indexOf(value) == index;
      }).sort();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('shops', this.store.query('shop', {}));
      this.set('categories', this.store.query('category', {}));
    }
  });

  _exports.default = _default;
});