define("console/templates/components/prescription-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+Ek95l/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"2xl\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"prescription-editor/header\",null,[[\"prescription\",\"close\",\"save\",\"delete\"],[[24,[\"prescription\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex sm:block\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-1/2 sm:w-full shadow-lg relative z-20\"],[8],[0,\"\\n      \"],[1,[28,\"prescription-editor/fields\",null,[[\"prescription\"],[[24,[\"prescription\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-1/2 sm:w-full h-auto sm:h-64 relative z-10 bg-center\\n      bg-no-repeat bg-contain bg-black flex items-center justify-center rounded-br sm:rounded-none\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"background-image: url(\",[24,[\"prescription\",\"imageUrl\"]],\")\"],null]],null]],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"prescription\",\"imageUrl\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"inline-block relative\"],[8],[0,\"\\n          \"],[1,[28,\"file-field\",null,[[\"class\",\"id\",\"filesDidChange\"],[\"absolute opacity-0 w-px h-px pointer-events-none\",\"prescription-upload\",[28,\"action\",[[23,0,[]],\"uploadPrescription\"],null]]]],false],[0,\"\\n          \"],[7,\"label\",true],[10,\"for\",\"prescription-upload\"],[10,\"class\",\"text-white underline cursor-pointer p-8\"],[8],[0,\"\\n            \"],[1,[28,\"if\",[[24,[\"isUploading\"]],\"Uploading...\",\"Upload prescription\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/prescription-editor.hbs"
    }
  });

  _exports.default = _default;
});