define("console/templates/components/receipt-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nFk4kYFb",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"uppercase font-normal text-xs tracking-wide border-b py-4 flex items-center justify-between\"],[8],[0,\"\\n  Receipt\\n\"],[4,\"if\",[[24,[\"order\",\"receiptIsPrinted\"]]],null,{\"statements\":[[0,\"    Printed \"],[1,[28,\"format-date\",[[24,[\"order\",\"receiptAt\"]]],[[\"format\"],[\"datetime\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isPrinting\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/loader.png\"],[10,\"alt\",\"\"],[10,\"class\",\"loader w-3\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"order\",\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"flex items-center justify-between py-3\\n    border-b border-grey-lighter cursor-pointer \",[28,\"if\",[[23,1,[\"isFulfilled\"]],\"text-blue\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleFulfillment\",[23,1,[]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"mr-2 \",[28,\"if\",[[23,1,[\"needsPrescription\"]],\"text-grey-light\",\"text-blue\"],null]]]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[[28,\"concat\",[\"checkbox-\",[28,\"if\",[[23,1,[\"isFulfilled\"]],\"marked\",\"blank\"],null],\"-outline\"],null]],[[\"width\",\"height\",\"class\"],[\"21\",\"21\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[23,1,[\"receiptTitle\"]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-auto text-right\"],[8],[0,\"\\n      \"],[1,[28,\"format-number\",[[23,1,[\"price\"]]],[[\"format\",\"locale\"],[[23,1,[\"shop\",\"currency\"]],[23,1,[\"shop\",\"locale\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/receipt-section.hbs"
    }
  });

  _exports.default = _default;
});