define("console/templates/components/ui-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3olmI2Sl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onChange\"]],[28,\"not\",[[24,[\"checked\"]]],null]],null]],[10,\"role\",\"button\"],[11,\"class\",[29,[\"relative w-8 p-2px rounded-full cursor-pointer flex\\n  \",[28,\"if\",[[24,[\"checked\"]],\"bg-green justify-end\",\"bg-grey-lighter\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-4 h-4 bg-white rounded-full shadow\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-switch.hbs"
    }
  });

  _exports.default = _default;
});