define("console/components/location-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    title: 'Location',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('locations', this.store.query('location', {}));
    },
    actions: {
      changeLocation: function changeLocation(location) {
        try {
          this.parent.set('location', location);
          this.parent.save();
        } catch (_ref) {
          var errors = _ref.errors;
          this.flashMessages.danger(errors[0].detail);
        }
      }
    }
  });

  _exports.default = _default;
});