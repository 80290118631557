define("console/templates/components/ui-cycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+dTRtSfn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"inline-block py-1 px-3 border rounded-full\\n  no-underline text-grey-dark text-sm \",[28,\"if\",[[24,[\"value\"]],\"border-blue text-blue\"],null]]]],[3,\"action\",[[23,0,[]],\"cycle\",[28,\"next\",[[24,[\"value\"]],[24,[\"options\"]]],null]]],[8],[0,\"\\n  \"],[1,[28,\"humanize\",[[28,\"or\",[[24,[\"value\"]],[24,[\"prompt\"]]],null]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-cycle.hbs"
    }
  });

  _exports.default = _default;
});