define("console/components/list-sort-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var match = Ember.computed.match;

  var _default = Ember.Component.extend({
    classNames: ['inline-flex', 'items-center', 'cursor-pointer'],
    classNameBindings: ['isCurrent:underline'],
    sortDesc: match('sort', /^-/),
    isCurrent: Ember.computed('sort', 'value', function () {
      var value = this.value;
      var sortDesc = this.sortDesc;
      var sort = this.sort;
      sort = sortDesc ? sort.substr(1) : sort;
      return sort == value;
    }),
    click: function click() {
      var value = this.value;
      var sortDesc = this.sortDesc;
      var sort = sortDesc ? value : '-' + value;
      this.change(sort);
    }
  });

  _exports.default = _default;
});