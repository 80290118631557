define("console/controllers/appointments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['interval', 'page', 'all'],
    interval: 'today',
    all: false,
    page: 1,
    intervals: Ember.A(['today', 'future', 'past']),
    appointments: Ember.computed.readOnly('model')
  });

  _exports.default = _default;
});