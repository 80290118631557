define("console/templates/components/ui-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lS7f2Aey",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"data-modal\",[24,[\"element\",\"id\"]]],[10,\"class\",\"pin z-50 bg-smoke fixed overflow-auto scrolling-touch\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"w-full max-w-\",[22,\"size\"],\" mx-auto p-8 sm:p-0\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"relative w-full bg-white rounded shadow-lg sm:rounded-none\"],[8],[0,\"\\n      \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"absolute pin-r pin-t -mr-4 -mt-4 p-2 bg-white rounded-full shadow text-black cursor-pointer sm:hidden print:hidden\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"close\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/ui-modal.hbs"
    }
  });

  _exports.default = _default;
});