define("console/components/ui-modal", ["exports", "ember-keyboard", "body-scroll-lock"], function (_exports, _emberKeyboard, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    keyboardActivated: true,
    contentElement: Ember.computed(function () {
      return this.element.querySelector('.overflow-auto');
    }),
    close: function close() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _bodyScrollLock.default.disableBodyScroll(this.contentElement);

      this.on((0, _emberKeyboard.keyUp)('Escape'), this.close);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _bodyScrollLock.default.enableBodyScroll(this.contentElement);
    },
    click: function click(event) {
      if (event.target.hasAttribute('data-modal')) {
        this.close();
      }
    }
  });

  _exports.default = _default;
});