define("console/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    title: attr('string'),
    googleMapsId: attr('string'),
    cashRegisterHost: attr('string'),
    cashRegisterToken: attr('string'),
    eyeExamMinutes: attr('number'),
    workHours: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    slots: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    breaks: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    shop: belongsTo('shop'),
    address: belongsTo('address'),
    images: hasMany('image'),
    imageSorting: Ember.A(['position:asc']),
    sortedImages: Ember.computed.sort('images', 'imageSorting'),
    image: Ember.computed.readOnly('sortedImages.firstObject'),
    isPublic: Ember.computed('tags.[]', function () {
      return !(this.tags || []).includes('private');
    }),
    hasBreaks: Ember.computed('breaks', function () {
      return Object.keys(this.breaks).length > 0;
    })
  });

  _exports.default = _default;
});