define("console/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    country: attr('string'),
    county: attr('string'),
    city: attr('string'),
    address1: attr('string'),
    address2: attr('string'),
    zip: attr('string'),
    company: attr('string'),
    companyNumber: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    phone: attr('string'),
    kind: attr('string'),
    iban: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var names = [this.firstName, this.lastName].filter(function (n) {
        return Ember.isPresent(n);
      });

      if (Ember.isPresent(names)) {
        return names.join(' ');
      }
    }),
    clone: function clone() {
      return this.store.createRecord('address', this.toJSON());
    }
  });

  _exports.default = _default;
});