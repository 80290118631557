define("console/controllers/shops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shops: Ember.computed.readOnly('model'),
    shopsSorting: Ember.A(['title:asc']),
    sortedShops: Ember.computed.sort('shops', 'shopsSorting')
  });

  _exports.default = _default;
});