define("console/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "console/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API_URL = _environment.default.API_URL;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: API_URL,
    namespace: 'api',
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          token = _this$get.token;

      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
      }
    }
  });

  _exports.default = _default;
});