define("console/models/order", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var readOnly = Ember.computed.readOnly,
      bool = Ember.computed.bool,
      filterBy = Ember.computed.filterBy,
      notEmpty = Ember.computed.notEmpty,
      setDiff = Ember.computed.setDiff,
      gt = Ember.computed.gt,
      lte = Ember.computed.lte,
      empty = Ember.computed.empty;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var TNT_TRACKING_URL = 'https://www.tnt.com/express/ro_ro/site/shipping-tools/tracking.html';

  var _default = Model.extend({
    currency: attr('string'),
    invoiceNumber: attr('number', {
      defaultValue: 0
    }),
    number: attr('number', {
      defaultValue: 0
    }),
    conversionRate: attr('number', {
      defaultValue: 1
    }),
    discountCode: attr('string'),
    discountPrice: attr('number', {
      defaultValue: 0
    }),
    shippingPrice: attr('number', {
      defaultValue: 0
    }),
    estimatedShippingPrice: attr('number', {
      defaultValue: 0
    }),
    estimatedShippingCurrency: attr('string'),
    total: attr('number', {
      defaultValue: 0
    }),
    trackingNumber: attr('string'),
    returnTrackingNumber: attr('string'),
    markAsPaid: attr('boolean', {
      defaultValue: false
    }),
    requestShipping: attr('boolean'),
    requestReturn: attr('boolean'),
    requestReturnAt: attr('date'),
    refundAmount: attr('number', {
      defaultValue: 0
    }),
    paymentRef: attr('string'),
    fulfilledAt: attr('date'),
    canceledAt: attr('date'),
    paidAt: attr('date'),
    printedAt: attr('date'),
    reviewedAt: attr('date'),
    refundedAt: attr('date'),
    createdAt: attr('date'),
    archivedAt: attr('date'),
    returnedAt: attr('date'),
    updatedAt: attr('date'),
    pickupAt: attr('date'),
    returnPickupAt: attr('date'),
    receiptAt: attr('date'),
    notes: attr('string'),
    skipCancelationEmail: attr('boolean'),
    utmSource: attr('string'),
    utmMedium: attr('string'),
    utmCampaign: attr('string'),
    utmTerm: attr('string'),
    utmContent: attr('string'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    shop: belongsTo('shop'),
    location: belongsTo('location'),
    customer: belongsTo('customer'),
    prescription: belongsTo('prescription'),
    discount: belongsTo('discount'),
    shippingAddress: belongsTo('address'),
    billingAddress: belongsTo('address'),
    returnAddress: belongsTo('address'),
    items: hasMany('item'),
    hasPrescription: bool('prescription.id'),
    hasShippingAddress: bool('shippingAddress.id'),
    hasBillingAddress: bool('billingAddress.id'),
    hasReturnAddress: bool('returnAddress.id'),
    hasLocation: bool('location.id'),
    hasTrackingNumber: bool('trackingNumber'),
    isPaid: bool('paidAt'),
    isFree: lte('total', 0),
    isPrinted: bool('printedAt'),
    isRefunded: bool('refundedAt'),
    isFulfilled: bool('fulfilledAt'),
    isCanceled: bool('canceledAt'),
    isArchived: bool('archivedAt'),
    isReturned: bool('returnedAt'),
    receiptIsPrinted: bool('receiptAt'),
    hasDiscount: gt('discountPrice', 0),
    hasItems: notEmpty('items'),
    hasNotes: notEmpty('notes'),
    itemsCount: readOnly('items.length'),
    htoItems: filterBy('items', 'hto'),
    hasHtoItems: notEmpty('htoItems'),
    htoItemsCount: readOnly('htoItems.length'),
    regularItems: setDiff('items', 'htoItems'),
    regularItemsCount: readOnly('regularItems.length'),
    hasRegularItems: notEmpty('regularItems'),
    itemsWithPrescription: filterBy('items', 'needsPrescription'),
    delayedShippingItems: filterBy('items', 'delayedShipping'),
    delayedShipping: notEmpty('delayedShippingItems'),
    needsPrescription: notEmpty('itemsWithPrescription'),
    needsBillingAddress: gt('regularItemsCount', 0),
    hasInvoice: bool('needsBillingAddress'),
    abandonedAt: readOnly('updatedAt'),
    openAt: readOnly('paidAt'),
    itemsWithProgressiveLenses: filterBy('items', 'needsProgressiveLenses'),
    needsProgressiveLenses: notEmpty('itemsWithProgressiveLenses'),
    paidCash: empty('paymentRef'),
    hasPaymentRef: notEmpty('paymentRef'),
    vat: Ember.computed('invoiceItems.@each.vat', 'discountVat', function () {
      return (this.items || []).reduce(function (sum, item) {
        return sum + item.totalVat;
      }, -this.discountVat);
    }),
    invoiceItems: Ember.computed('regularItems.@each.vatRate', function () {
      return (this.regularItems || []).sortBy('vatRate');
    }),
    maxVatRate: Ember.computed('invoiceItems.lastObject.vatRate', function () {
      return (this.invoiceItems || []).reduce(function (max, item) {
        return item.vatRate > max ? item.vatRate : max;
      }, 0);
    }),
    discountVat: Ember.computed('discountPrice', 'maxVatRate', function () {
      return this.discountPrice - this.discountPrice / (1 + this.maxVatRate / 100);
    }),
    productsCount: Ember.computed('items.@each.quantity', function () {
      return (this.items || []).reduce(function (sum, item) {
        return sum + item.quantity;
      }, 0);
    }),
    isHto: Ember.computed('hasItems', 'htoItemsCount', 'itemsCount', function () {
      return this.hasItems && this.htoItemsCount == this.itemsCount;
    }),
    invoiceAddress: Ember.computed('hasBillingAddress', 'hasShippingAddress', function () {
      return this.hasBillingAddress ? this.billingAddress : this.shippingAddress;
    }),
    invoiceNumberWithPrefix: Ember.computed('shop.countryCode', 'invoiceNumber', function () {
      return this.get('shop.countryCode') + "".concat(this.invoiceNumber).padStart(6, '0');
    }),
    htoOverdue: Ember.computed('isFulfilled', 'isReturned', 'returnPickupAt', function () {
      return this.isFulfilled && !this.isReturned && (0, _moment.default)().diff(this.returnPickupAt, 'days') > 1;
    }),
    trackingUrl: Ember.computed('trackingNumber', function () {
      if (this.trackingNumber) {
        return "".concat(TNT_TRACKING_URL, "?searchType=con&cons=").concat(this.trackingNumber);
      }
    }),
    returnTrackingUrl: Ember.computed('returnTrackingNumber', function () {
      if (this.returnTrackingNumber) {
        return "".concat(TNT_TRACKING_URL, "?searchType=con&cons=").concat(this.returnTrackingNumber);
      }
    }),
    fulfilledToday: Ember.computed('fulfilledAt', function () {
      return this.fulfilledAt && (0, _moment.default)().isSame(this.fulfilledAt, 'day');
    }),
    isLost: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('lost');
    }),
    markAsFulfilled: function markAsFulfilled() {
      this.set('fulfilledAt', new Date());
    },
    markAsCanceled: function markAsCanceled() {
      this.set('canceledAt', new Date());
    },
    markAsArchived: function markAsArchived() {
      this.set('archivedAt', new Date());
    },
    markAsReturned: function markAsReturned() {
      this.set('returnedAt', new Date());
    },
    markAsPrinted: function markAsPrinted() {
      this.set('printedAt', new Date());
    },
    markReceiptAsPrinted: function markReceiptAsPrinted() {
      this.set('receiptAt', new Date());
    },
    markAsReopened: function markAsReopened() {
      var attrs = ['printedAt', 'fulfilledAt', 'archivedAt', 'returnedAt', 'pickupAt', 'returnPickupAt', 'trackingNumber', 'returnTrackingNumber'];

      for (var _i = 0; _i < attrs.length; _i++) {
        var _attr = attrs[_i];
        this.set(_attr, null);
      }
    }
  });

  _exports.default = _default;
});