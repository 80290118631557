define("console/templates/discounts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2BO0uPib",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"discount-editor\",null,[[\"discount\",\"onSave\",\"close\"],[[24,[\"discount\"]],[28,\"queue\",[[28,\"refresh-route\",[\"discounts\"],null],[28,\"transition-to\",[\"discounts.discount\",[24,[\"discount\"]]],null]],null],[28,\"transition-to\",[\"discounts\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/discounts/new.hbs"
    }
  });

  _exports.default = _default;
});