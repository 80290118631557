define("console/templates/components/images-section/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WhKXMWHN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[24,[\"image\",\"mdUrl\"]]],[10,\"alt\",\"\"],[10,\"class\",\"block w-full pointer-events-none\"],[8],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"absolute pin-t pin-r p-1 bg-white shadow rounded-full -m-2 text-black cursor-pointer\"],[3,\"action\",[[23,0,[]],\"delete\"]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"close\"],[[\"width\",\"height\",\"class\"],[\"12\",\"12\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"ui-autocomplete\",null,[[\"selection\",\"update\"],[[24,[\"image\",\"tags\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/images-section/image.hbs"
    }
  });

  _exports.default = _default;
});