define("console/templates/components/product-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mx4TNDHE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"lg\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"product-editor/header\",null,[[\"product\",\"close\",\"save\",\"printPackageSlip\",\"delete\",\"clone\"],[[24,[\"product\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null],[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showPackageSlip\"]]],null],true],null],[28,\"action\",[[23,0,[]],\"delete\"],null],[28,\"action\",[[23,0,[]],\"clone\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[1,[28,\"product-editor/fields\",null,[[\"product\"],[[24,[\"product\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"product\",\"isNew\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"images-section\",null,[[\"target\",\"images\"],[[24,[\"product\"]],[24,[\"product\",\"images\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8 bg-grey-lightest\"],[8],[0,\"\\n    \"],[1,[28,\"product-editor/linked\",null,[[\"product\"],[[24,[\"product\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showPackageSlip\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"print-wormhole\"]],{\"statements\":[[0,\"    \"],[1,[28,\"package-slip\",null,[[\"product\",\"close\"],[[24,[\"product\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showPackageSlip\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/product-editor.hbs"
    }
  });

  _exports.default = _default;
});