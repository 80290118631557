define("console/helpers/transition-to", ["exports", "ember-transition-helper/helpers/transition-to"], function (_exports, _transitionTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _transitionTo.default;
    }
  });
  Object.defineProperty(_exports, "transitionTo", {
    enumerable: true,
    get: function get() {
      return _transitionTo.transitionTo;
    }
  });
});