define("console/templates/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "01BpAgVA",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Locations\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Locations\"]]],false],[0,\"\\n  \"],[1,[28,\"location-list\",null,[[\"locations\"],[[24,[\"sortedLocations\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/locations.hbs"
    }
  });

  _exports.default = _default;
});