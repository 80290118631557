define("console/templates/customers/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QvWS9tR9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"customer\",\"fullName\"]]],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[1,[28,\"customer-editor\",null,[[\"customer\",\"onSave\",\"onDelete\",\"close\"],[[24,[\"customer\"]],[28,\"transition-to\",[\"customers\"],null],[28,\"transition-to\",[\"customers\"],null],[28,\"transition-to\",[\"customers\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/customers/customer.hbs"
    }
  });

  _exports.default = _default;
});