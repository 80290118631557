define("console/models/shop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CURRENCIES = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var CURRENCIES = ['EUR', 'RON'];
  _exports.CURRENCIES = CURRENCIES;

  var _default = Model.extend({
    CURRENCIES: CURRENCIES,
    title: attr('string'),
    email: attr('string'),
    currency: attr('string'),
    conversionRate: attr('number', {
      defaultValue: 1
    }),
    vatRate: attr('number', {
      defaultValue: 0
    }),
    reducedVatRate: attr('number', {
      defaultValue: 0
    }),
    htoLimit: attr('number', {
      defaultValue: 0
    }),
    locale: attr('string', {
      defaultValue: 'en'
    }),
    locales: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    countryCode: attr('string'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    ordersCount: attr('number', {
      defaultValue: 0
    }),
    invoicedOrdersCount: attr('number', {
      defaultValue: 0
    }),
    tntAccount: attr('string'),
    contactAddress: belongsTo('address'),
    billingAddress: belongsTo('address'),
    hasHomeTryOn: Ember.computed.gt('htoLimit', 0)
  });

  _exports.default = _default;
});