define("console/components/product-editor/fields", ["exports", "console/models/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mapBy = Ember.computed.mapBy;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    types: _product.PRODUCT_TYPES,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('categories', this.store.query('category', {}));
      this.set('tags', this.store.query('tag', {
        filter: {
          scope: 'product'
        }
      }));
    },
    tagOptions: mapBy('tags', 'title')
  });

  _exports.default = _default;
});