define("console/templates/products/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ay52r7RM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"product\",\"fullTitle\"]]],null],false],[0,\"\\n\"],[1,[28,\"product-editor\",null,[[\"product\",\"onSave\",\"onDelete\",\"onClone\",\"close\"],[[24,[\"product\"]],[28,\"transition-to\",[\"products\"],null],[28,\"transition-to\",[\"products\"],null],[28,\"queue\",[[28,\"refresh-route\",[\"products\"],null],[28,\"transition-to\",[\"products\"],null]],null],[28,\"transition-to\",[\"products\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/products/product.hbs"
    }
  });

  _exports.default = _default;
});