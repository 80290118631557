define("console/templates/components/list-search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Er3y7z7l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[22,\"identifier\"]],[10,\"class\",\"absolute p-1 pointer-events-none\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"magnify\"],[[\"width\",\"height\",\"class\"],[\"14\",\"14\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"input\",true],[11,\"id\",[22,\"identifier\"]],[10,\"class\",\"block appearance-none rounded-none b-0 p-0 bg-transparent border-b-2\\n    w-48 sm:w-full pl-6 text-black text-sm pb-2 leading-normal outline-none focus:border-blue\"],[11,\"value\",[22,\"value\"]],[10,\"autocomplete\",\"off\"],[10,\"placeholder\",\"Search...\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],[24,[\"update\"]]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/list-search-input.hbs"
    }
  });

  _exports.default = _default;
});