define("console/templates/jobs/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19OILbA2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"job\",\"id\"]]],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[1,[28,\"job-editor\",null,[[\"job\",\"onReschedule\",\"onDelete\",\"close\"],[[24,[\"job\"]],[28,\"transition-to\",[\"jobs\"],null],[28,\"transition-to\",[\"jobs\"],null],[28,\"transition-to\",[\"jobs\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/jobs/job.hbs"
    }
  });

  _exports.default = _default;
});