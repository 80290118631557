define("console/templates/components/shop-editor/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a22sIjvr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center bg-grey-lightest p-8 rounded-t sm:rounded-none sm:p-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-auto flex items-center\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-blue p-2 bg-grey-lighter rounded-full hidden sm:block\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"arrow-left\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"text-xl font-normal sm:hidden\"],[8],[0,\"\\n      \"],[1,[24,[\"shop\",\"title\"]],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-grey\"],[8],[0,\"\\n        — \"],[1,[28,\"format-number\",[[24,[\"shop\",\"invoicedOrdersCount\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--green ml-2\"],[3,\"action\",[[23,0,[]],[24,[\"save\"]]]],[8],[0,\"\\n      \"],[1,[28,\"if\",[[24,[\"shop\",\"isSaving\"]],\"Saving...\",[28,\"if\",[[24,[\"shop\",\"isNew\"]],\"Add Shop\",\"Save Changes\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/shop-editor/header.hbs"
    }
  });

  _exports.default = _default;
});