define("console/templates/components/manager-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EwworHut",
    "block": "{\"symbols\":[\"manager\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"table w-full\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"table-row text-sm font-medium\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-cell py-4 text-left\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-cell py-4 text-left sm:hidden\"],[8],[0,\"Email\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-cell py-4 text-right\"],[8],[0,\"Location\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"managers\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"table-row text-black no-underline hover:bg-grey-lightest\",\"managers.manager\",[23,1,[]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"table-cell border-t border-gray py-4 text-left \",[28,\"unless\",[[23,1,[\"isActive\"]],\"line-through\"],null]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"table-cell border-t border-gray py-4 text-left sm:hidden\"],[8],[0,\"\\n        \"],[1,[23,1,[\"email\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"table-cell border-t border-gray py-4 text-right\"],[8],[0,\"\\n        \"],[1,[23,1,[\"location\",\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/manager-list.hbs"
    }
  });

  _exports.default = _default;
});