define("console/templates/components/product-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w5lVq/r+",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[4,\"ui-modal\",null,[[\"size\",\"close\"],[\"lg\",[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"product-search/header\",null,[[\"product\",\"close\"],[[24,[\"product\"]],[28,\"action\",[[23,0,[]],[24,[\"close\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4 -mb-8\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedProducts\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"w-1/4 px-4 mb-8 sm:w-1/2\"],[8],[0,\"\\n          \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"relative border rounded hover:shadow\"],[3,\"action\",[[23,0,[]],[24,[\"select\"]],[23,1,[]]]],[8],[0,\"\\n            \"],[7,\"img\",true],[11,\"src\",[23,1,[\"image\",\"mdUrl\"]]],[10,\"alt\",\"\"],[10,\"class\",\"block w-full pointer-events-none rounded\"],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"absolute pin-l pin-t pin-r p-6 text-center text-xs\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"font-medium\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\" \"],[1,[23,1,[\"variant\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/product-search.hbs"
    }
  });

  _exports.default = _default;
});