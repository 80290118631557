define("console/models/appointment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    startsAt: attr('date'),
    endsAt: attr('date'),
    completedAt: attr('date'),
    canceledAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    utmSource: attr('string'),
    utmMedium: attr('string'),
    utmCampaign: attr('string'),
    utmTerm: attr('string'),
    utmContent: attr('string'),
    shop: belongsTo('shop'),
    location: belongsTo('location'),
    customer: belongsTo('customer'),
    prescription: belongsTo('prescription'),
    manager: belongsTo('manager'),
    hasShop: Ember.computed.bool('shop.id'),
    hasCustomer: Ember.computed.bool('customer.id'),
    hasPrescription: Ember.computed.bool('prescription.id'),
    isCompleted: Ember.computed.bool('completedAt'),
    isCanceled: Ember.computed.bool('canceledAt'),
    markAsCompleted: function markAsCompleted() {
      this.set('completedAt', new Date());
    },
    markAsCanceled: function markAsCanceled() {
      this.set('canceledAt', new Date());
    }
  });

  _exports.default = _default;
});