define("console/components/order-print-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    orderURL: Ember.computed('order', function () {
      var path = this.router.urlFor('orders.order', this.order);
      var _window$location = window.location,
          protocol = _window$location.protocol,
          host = _window$location.host;
      return "".concat(protocol, "//").concat(host).concat(path);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      window.onafterprint = Ember.run.bind(this, 'afterPrint');
      Ember.run.scheduleOnce('afterRender', window, print);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.onafterprint = null;
    },
    afterPrint: function afterPrint() {
      this.order.markAsPrinted();
      this.order.save();
      this.close();
    }
  });

  _exports.default = _default;
});