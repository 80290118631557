define("console/components/etw/module-style-detail", ["exports", "ember-cli-tailwind/utils/classes-for-module-style"], function (_exports, _classesForModuleStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    etwTailwindStyleguide: Ember.inject.service(),
    moduleStyle: Ember.computed.reads('etwTailwindStyleguide.selectedModuleStyle'),
    activeResponsiveClass: 'all',
    activeState: 'none',
    detailStyles: Ember.computed('moduleStyle', 'activeResponsiveClass', 'activeState', function () {
      var moduleStyle = this.moduleStyle;
      var activeResponsiveClass = this.activeResponsiveClass;
      var responsivePrefix = activeResponsiveClass === 'all' ? '' : "".concat(activeResponsiveClass, ":");
      var activeState = this.activeState;
      var statePrefix = activeState === 'none' ? '' : "".concat(activeState, ":");
      return (0, _classesForModuleStyle.default)(moduleStyle).map(function (cssClass) {
        return "".concat(responsivePrefix).concat(statePrefix).concat(cssClass);
      });
    }),
    actions: {
      highlightStyle: function highlightStyle(style) {
        var _this = this;

        this.set('highlightedStyle', style);
        Ember.run.later(function () {
          _this.set('highlightedStyle', null);
        }, 1500);
      }
    }
  });

  _exports.default = _default;
});