define("console/controllers/discounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var readOnly = Ember.computed.readOnly;

  var _default = Ember.Controller.extend({
    queryParams: ['search', 'notes'],
    search: '',
    notes: '',
    discounts: readOnly('model'),
    filteredDiscounts: Ember.computed('discounts.[]', 'notes', 'search', function () {
      var _this = this;

      var search = new RegExp(this.search, 'i');
      return (this.discounts || []).filter(function (discount) {
        return search.test(discount.code) && (Ember.isEmpty(_this.notes) || discount.notes == _this.notes);
      });
    })
  });

  _exports.default = _default;
});