define("console/templates/managers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZ9rTZE7",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Managers\"],[[\"separator\"],[\" - \"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto p-8\"],[8],[0,\"\\n  \"],[1,[28,\"navigation-bar\",null,[[\"title\"],[\"Managers\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-between mb-8 sm:mb-4\"],[8],[0,\"\\n    \"],[1,[28,\"list-search-input\",null,[[\"value\",\"identifier\",\"class\",\"update\"],[[24,[\"search\"]],\"managers-search\",\"sm:flex-auto\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"search\"]]],null]],null]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--sm btn--grey ml-6\",\"managers.new\"]],{\"statements\":[[0,\"        Add Manager\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"manager-list\",null,[[\"managers\"],[[24,[\"sortedManagers\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/managers.hbs"
    }
  });

  _exports.default = _default;
});