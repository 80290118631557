define("console/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "invoice": {
      "category": {
        "clipOns": "Clip-on",
        "glasses": "Glasses",
        "sunglasses": "Sunglasses"
      },
      "label": {
        "discount": "Discount",
        "item": "Item",
        "orderNumber": "Order number",
        "payment": "Payment reference",
        "price": "Price",
        "quantity": "({count} Pcs.)",
        "total": "Total",
        "totalVat": "Total VAT",
        "vat": "VAT",
        "vatRate": "VAT Rate"
      },
      "surcharge": {
        "blueLightFilter": "Blue light filter",
        "degressiveLenses": "Degressive lenses",
        "highIndex": "Premium lenses",
        "lightResponsiveFilter": "Light responsive",
        "noPrescription": "Non prescription",
        "polarizingFilter": "Polarizing filter",
        "progressiveLenses": "Progressive lenses",
        "singleVisionLenses": "Single vision lenses"
      },
      "title": "Invoice"
    },
    "pos": {
      "blueLightFilter": "Blue",
      "highIndex": "Premium",
      "lightResponsiveFilter": "Helio",
      "polarizingFilter": "Polar"
    },
    "prescription": {
      "label": {
        "add": "ADD",
        "axis": "AX",
        "cylinder": "CYL",
        "diagnosis": "Diagnosis",
        "medic": "Ophthalmologist",
        "optometrist": "Optometrist",
        "prism": "PR",
        "pupilDistance": "PD",
        "segmentHeight": "SH",
        "sphere": "SPH"
      },
      "title": "Prescription"
    },
    "transport": {
      "title": "Goods delivery note"
    }
  };
  _exports.default = _default;
});