define("console/templates/components/breaks-editor/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Es5WlDxT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-3/5 px-4 sm:w-full sm:mb-8\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input\",null,[[\"id\",\"type\",\"label\",\"placeholder\",\"value\",\"oninput\"],[\"break-day\",\"date\",\"Day\",\"YYYY-MM-DD\",[24,[\"break\",\"day\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"break\",\"day\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-1/5 px-4 sm:w-1/2\"],[8],[0,\"\\n    \"],[1,[28,\"ui-select\",null,[[\"id\",\"label\",\"options\",\"value\",\"onchange\"],[\"break-from\",\"From\",[24,[\"timeOptions\"]],[24,[\"break\",\"from\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"break\",\"from\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-1/5 px-4 sm:w-1/2\"],[8],[0,\"\\n    \"],[1,[28,\"ui-select\",null,[[\"id\",\"label\",\"options\",\"value\",\"onchange\"],[\"break-to\",\"To\",[24,[\"timeOptions\"]],[24,[\"break\",\"to\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"break\",\"to\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "console/templates/components/breaks-editor/fields.hbs"
    }
  });

  _exports.default = _default;
});