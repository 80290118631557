define("console/tailwind/config/tailwind", ["exports", "tailwindcss/plugins/container", "console/tailwind/config/colors", "console/tailwind/config/screens", "console/tailwind/config/fonts", "console/tailwind/config/text-sizes", "console/tailwind/config/font-weights", "console/tailwind/config/line-height", "console/tailwind/config/letter-spacing", "console/tailwind/config/text-colors", "console/tailwind/config/background-colors", "console/tailwind/config/background-size", "console/tailwind/config/border-widths", "console/tailwind/config/border-colors", "console/tailwind/config/border-radius", "console/tailwind/config/width", "console/tailwind/config/height", "console/tailwind/config/min-width", "console/tailwind/config/min-height", "console/tailwind/config/max-width", "console/tailwind/config/max-height", "console/tailwind/config/padding", "console/tailwind/config/margin", "console/tailwind/config/negative-margin", "console/tailwind/config/shadows", "console/tailwind/config/z-index", "console/tailwind/config/opacity", "console/tailwind/config/svg-fill", "console/tailwind/config/svg-stroke"], function (_exports, _container, _colors, _screens, _fonts, _textSizes, _fontWeights, _lineHeight, _letterSpacing, _textColors, _backgroundColors, _backgroundSize, _borderWidths, _borderColors, _borderRadius, _width, _height, _minWidth, _minHeight, _maxWidth, _maxHeight, _padding, _margin, _negativeMargin, _shadows, _zIndex, _opacity, _svgFill, _svgStroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    colors: _colors.default,
    screens: _screens.default,
    fonts: _fonts.default,
    textSizes: _textSizes.default,
    fontWeights: _fontWeights.default,
    leading: _lineHeight.default,
    tracking: _letterSpacing.default,
    textColors: _textColors.default,
    backgroundColors: _backgroundColors.default,
    backgroundSize: _backgroundSize.default,
    borderWidths: _borderWidths.default,
    borderColors: _borderColors.default,
    borderRadius: _borderRadius.default,
    width: _width.default,
    height: _height.default,
    minWidth: _minWidth.default,
    minHeight: _minHeight.default,
    maxWidth: _maxWidth.default,
    maxHeight: _maxHeight.default,
    padding: _padding.default,
    margin: _margin.default,
    negativeMargin: _negativeMargin.default,
    shadows: _shadows.default,
    zIndex: _zIndex.default,
    opacity: _opacity.default,
    svgFill: _svgFill.default,
    svgStroke: _svgStroke.default,
    modules: {
      appearance: ['responsive'],
      backgroundAttachment: ['responsive'],
      backgroundColors: ['responsive', 'hover'],
      backgroundPosition: ['responsive'],
      backgroundRepeat: ['responsive'],
      backgroundSize: ['responsive'],
      borderColors: ['responsive', 'hover', 'focus'],
      borderRadius: ['responsive'],
      borderStyle: ['responsive'],
      borderWidths: ['responsive'],
      cursor: ['responsive'],
      display: ['responsive'],
      flexbox: ['responsive'],
      float: ['responsive'],
      fonts: ['responsive'],
      fontWeights: ['responsive', 'hover'],
      height: ['responsive'],
      leading: ['responsive'],
      lists: ['responsive'],
      margin: ['responsive'],
      maxHeight: ['responsive'],
      maxWidth: ['responsive'],
      minHeight: ['responsive'],
      minWidth: ['responsive'],
      negativeMargin: ['responsive'],
      opacity: ['responsive'],
      overflow: ['responsive'],
      padding: ['responsive'],
      pointerEvents: ['responsive'],
      position: ['responsive'],
      resize: ['responsive'],
      shadows: ['responsive', 'hover'],
      svgFill: [],
      svgStroke: [],
      textAlign: ['responsive'],
      textColors: ['responsive', 'hover'],
      textSizes: ['responsive'],
      textStyle: ['responsive', 'hover'],
      tracking: ['responsive'],
      userSelect: ['responsive'],
      verticalAlign: ['responsive'],
      visibility: ['responsive'],
      whitespace: ['responsive'],
      width: ['responsive'],
      zIndex: ['responsive']
    },

    /*
    |-----------------------------------------------------------------------------
    | Plugins                                https://tailwindcss.com/docs/plugins
    |-----------------------------------------------------------------------------
    |
    | Here is where you can register any plugins you'd like to use in your
    | project. Tailwind's built-in `container` plugin is enabled by default to
    | give you a Bootstrap-style responsive container component out of the box.
    |
    | Be sure to view the complete plugin documentation to learn more about how
    | the plugin system works.
    |
    */
    plugins: [(0, _container.default)({// center: true,
      // padding: '1rem',
    })],

    /*
    |-----------------------------------------------------------------------------
    | Advanced Options         https://tailwindcss.com/docs/configuration#options
    |-----------------------------------------------------------------------------
    |
    | Here is where you can tweak advanced configuration options. We recommend
    | leaving these options alone unless you absolutely need to change them.
    |
    */
    options: {
      prefix: '',
      important: false,
      separator: ':'
    }
  };
  _exports.default = _default;
});