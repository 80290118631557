define("console/models/discount", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPES = _exports.FIXED_TYPE = _exports.PERCENTAGE_TYPE = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var equal = Ember.computed.equal,
      alias = Ember.computed.alias;
  var PERCENTAGE_TYPE = 'percentage';
  _exports.PERCENTAGE_TYPE = PERCENTAGE_TYPE;
  var FIXED_TYPE = 'fixed';
  _exports.FIXED_TYPE = FIXED_TYPE;
  var TYPES = [PERCENTAGE_TYPE, FIXED_TYPE];
  _exports.TYPES = TYPES;

  var _default = Model.extend({
    TYPES: TYPES,
    code: attr('string'),
    kind: attr('string', {
      defaultValue: PERCENTAGE_TYPE
    }),
    value: attr('number', {
      defaultValue: 0
    }),
    maxUsageCount: attr('number', {
      defaultValue: 0
    }),
    minItemCount: attr('number', {
      defaultValue: 0
    }),
    startsAt: attr('raw'),
    endsAt: attr('raw'),
    usageCount: attr('number', {
      defaultValue: 0
    }),
    notes: attr('string'),
    surcharge: attr('string'),
    shop: belongsTo('shop'),
    category: belongsTo('category'),
    type: alias('kind'),
    isPercentage: equal('kind', PERCENTAGE_TYPE),
    isFixed: equal('kind', FIXED_TYPE),
    isUsed: Ember.computed('usageCount', 'maxUsageCount', function () {
      return this.usageCount == this.maxUsageCount;
    }),
    price: Ember.computed('value', 'isFixed', function () {
      return this.isFixed ? this.value / 100 : 0;
    })
  });

  _exports.default = _default;
});